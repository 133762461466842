import React, { useEffect } from 'react';
import 'cleave.js/dist/addons/cleave-phone.ng';
import Cleave from 'cleave.js/react';
import {
  MyPhoneInput,
  MySelect,
  MyTextInput,
  PaymentCheckbox,
} from 'Atoms/Formik';
import { Countries, States } from 'Lib/constants';
import { Formik, Form } from 'formik';
import { PUCPaymentSchema } from 'Lib/validators';
import API from 'Services/api';
import router from 'next/router';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

export default function Payment({
  setPaymentLoading,
  handleClose,
  grandTotal,
  handleUpdateReservation,
  setPaymentFail,
}) {
  var initalValues = {
    creditCardValue: '',
    creditCardType: '',
    expDate: '',
    cvv: '',
    firstName: '',
    lastName: '',
    country: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    telephone: '',
    saveCustomerProfile: false,
  };
  if (localStorage.getItem('billingInfo')) {
    const customerInfo = JSON.parse(localStorage.getItem('billingInfo'));
    const { mobile, stateProvince } = customerInfo;
    initalValues = {
      ...initalValues,
      ...customerInfo,
      state: stateProvince,
      telephone: mobile,
    };
  }
  useEffect(() => {
    function apiHit() {
      let accept = document.createElement('script');
      accept.setAttribute('src', process.env.NEXT_PUBLIC_PG_URL);
      document.body.appendChild(accept);
      if (process.client) {
        window.checkout = this.checkout;
      }
    }
    apiHit();
  }, []);

  return (
    <div>
      
    </div>
  );
}
