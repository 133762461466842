import React, { useEffect, useState } from 'react';
import 'Styles/globals.css';
import '../../public/css/bootstrap.min.css';
import '../../public/css/hawaii.css';
import '../../public/css/datepicker.css';
import { Layout } from 'Molecules/';
import { OrderProvider } from 'Lib/contexts/OrderContext';
import { useRouter } from 'next/router';
import { TimerProvider } from 'Lib/contexts/TimerContext';
import { SubFooter } from 'Atoms/';
import jsCookie from 'js-cookie';
import { nonAuth } from 'src/constants/nonAuthRoutes';
import Script from 'next/script';
import { Header } from 'Molecules/PUC';
import { Config } from 'Lib/config';
import API from "Services/api";

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const { pathname } = router;

  const [cookieCheck, setCookieCheck] = useState(false);
  const flag = nonAuth.includes(pathname);

  useEffect(() => {
    if (!jsCookie.get('login')) {
      jsCookie.remove('token');
      jsCookie.remove('userId');
      if (!flag) router.push('/');
    }
    setCookieCheck(true);
    
    async function apiHit() {
      try {
        const { data } = await API.get("/parks/notices")
    
        if (data?.message === "Success") {
          localStorage.setItem('notices', JSON.stringify(data?.data));
        }
      } catch (error) {
        console.log(error);
      }
    }
    apiHit();
  }, []);

  return (
    <>
      <Script src='https://www.googletagmanager.com/gtag/js?id=G-MCGWFNR4Y0' />
      <Script id='google-analytics'>
        {`window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              
              gtag('config', 'G-MCGWFNR4Y0');
              `}
      </Script>
      <Script src="https://www.googletagmanager.com/gtag/js?id=G-9Z1SDE5HQY" strategy="afterInteractive"/>
      <Script id="google-analytics" strategy="afterInteractive">
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-9Z1SDE5HQY');
        `}
      </Script>
      
      
      <Script id="freshChat" strategy="afterInteractive">
        {`
          window.fcWidget.init({
              token: '${Config.FRESHCHAT_TOKEN}',
              host: 'https://wchat.freshchat.com',
              siteId: '${Config.FRESHCHAT_ID}',
              config: {
                hideFAQ: true,
                cssNames: {
                  widget: "freshchat-widget"
                }
              }
          });
        `}
      </Script>
      
      
      {cookieCheck && (
        <>
          {flag ? (
            <OrderProvider>
              <TimerProvider>
                <Header flag={false} />
                <Layout>
                  <Component {...pageProps} />
                </Layout>
              </TimerProvider>
            </OrderProvider>
          ) : (
            <OrderProvider>
              <TimerProvider>
                <Header flag={true} />
                <Layout>
                  <Component {...pageProps} />
                  <SubFooter />
                </Layout>
              </TimerProvider>
            </OrderProvider>
          )}
        </>
      )}
    </>
  );
}

export default MyApp;
