import React, { useEffect, useState } from 'react';
import { Loader } from 'Atoms/';
import Link from 'next/link';
import API from 'Services/api';
import toast, { Toaster } from 'react-hot-toast';

const Index = () => {
  const [registerData, setRegisterData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem('puc_register_data')) {
      router.push('');
    } else {
      setLoading(true);
      const registerDataParsed = JSON.parse(
        localStorage.getItem('puc_register_data')
      );
      setRegisterData(registerDataParsed);
    }
  }, []);

  if (!loading) {
    return <Loader />;
  }

  const { email } = registerData;
  const handleResendLink = async () => {
    try {
      const { data } = await API.post('/puc/resend-verification-mail', {
        email,
        host: process.env.NEXT_PUBLIC_WEB_URL,
      });
      if (data?.message === 'Success') {
        toast.success('Verification link sent successfully');
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className='container'>
      <div>
        <Toaster />
      </div>
      <div className='row row-padding'>
        <div className='col-xl-12 col-md-12'>
          <h2>Login credentials setup:</h2>

          <p className='less-padding'>
            Please check your email (and spam folder) for the link we sent to
            {` ${email}`} to finalize the creation of your account and password.
            We recommend the use of a password manager for optimal security.
            Passwords must be at least 8 characters and contain all of the
            following:
          </p>
          <ul>
            <li>lowercase letter(s)</li>
            <li>uppercase letter(s)</li>
            <li>symbol(s)</li>
            <li>number(s)</li>
          </ul>
          <p>
            <em>
              <strong>Please note:</strong> these login credentials
              (username/email and password) are for you only and may not be
              shared with co-workers. Each employee must use their own login
              credentials.
            </em>
          </p>
          <p>
            If you do not receive this link within 15 minutes, please contact
            dlnr.dsp@hawaii.gov
          </p>
        </div>
      </div>

      <div className='row row-padding-bottom'>
        <div className='col-md-6 col-sm-6 col-xs-12'>
          <button
            className='btn btn-primary btn-outline-primary'
            onClick={handleResendLink}
          >
            Resend confirmation link
          </button>
          <Link href='/'>
            <a className='btn btn-primary ml-2'>Login</a>
          </Link>
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default Index;
