import React from 'react';
import { useField } from 'formik';

export default function MyTextInput({ ...props }) {
  const [field, meta] = useField(props);
  return (
    <>
      <input className='form-control' {...field} {...props} />
      {meta.touched && meta.error ? (
        <div
          style={{
            marginLeft: '0.5rem',
            marginBottom: '0.5rem',
            color: '#E95F5F',
          }}
        >
          {meta.error}
        </div>
      ) : null}
    </>
  );
}
