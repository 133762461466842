import React, { useState, useEffect, useRef, useContext } from 'react';
import API from 'Services/api';
import { Loader } from 'Atoms/';
import ReservationEntries from './ReservationEntries';
import { errorObj } from 'Lib/constants';
import PhoneInput from 'react-phone-number-input';

import {
  MySelect,
  MyTextInput,
  MyPhoneInput,
  PaymentCheckbox,
} from 'Atoms/Formik';
import { Formik, Form, Field } from 'formik';
import { PUCBillingInfoSchema } from 'Lib/validators';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { SubHeader } from '..';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import Timer from 'src/components/Timer';
import { TimerContext } from 'Lib/contexts/TimerContext';
import toast, { Toaster } from 'react-hot-toast';
import { Modal } from 'react-bootstrap';

export default function Index() {
  const { stopTimer } = useContext(TimerContext);

  const router = useRouter();
  const [grandTotal, setGrandTotal] = useState('');
  const [feeTotal, setFeeTotal] = useState('');
  const [reservationData, setReservationData] = useState([]);
  const [removeFlag, setRemoveFlag] = useState(false);
  const firstUpdate = useRef(true);
  const [customerData, setCustomerData] = useState(null);
  const [PaymentsGroup, setPaymentsGroup] = useState([]);
  const [show,setShow]=useState(false)
  const [cardDelete,setCardDelete]=useState(null);

  useEffect(() => {
    async function apiHit() {
      try {
        const { data } = await API.get('/puc/getCart');
        //const PaymentData = await API.get('puc/paymentprofiles');
        // console.log(PaymentData);
        setGrandTotal(data?.data?.orderTotal);
        setFeeTotal(data?.data?.addOn);
        setReservationData(data?.data?.reservationData);
        //setPaymentsGroup(PaymentData.data.data.paymentProfiles);
      } catch (error) {
        console.warn(error.response.data.error);
      }
    }

    apiHit();
    setCustomerData(JSON.parse(localStorage.getItem('puc_login_data')).user);
    localStorage.removeItem('updateReservationData');
  }, []);

  // redirect empty cart to reservation page
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    // console.log(reservationData.length)
    if (!reservationData.length) {
      stopTimer();
      toast('Shopping cart is empty', errorObj);
      router.push('/reservations');
    }
  }, [reservationData]);

  // useEffect(() => clearTimer(getDeadTime()), []);

  // console.log(reservationData);

  // Remove Item Functionality
  const removeCartItem = async (id, idx, totalPrice) => {
    try {
      await API.get(`/puc/removeItem?id=${id}`);

      /*const newCartItem = reservationData.filter((item, index) => index != idx);
      const newTotalPrice = grandTotal - totalPrice;
      setReservationData(newCartItem);
      setGrandTotal(Number(newTotalPrice).toFixed(2));*/
      
      const { data } = await API.get('/puc/getCart');
      
      setGrandTotal(data?.data?.orderTotal);
      setFeeTotal(data?.data?.addOn);
      setReservationData(data?.data?.reservationData);
      
      setRemoveFlag(true);
    } catch (err) {
      console.log(err);
    }
  };

  if (!grandTotal || !reservationData) {
    return <Loader />;
  }

  var initialValues = {
    firstName: customerData ? customerData.firstName : '',
    lastName: customerData ? customerData.lastName : '',
    country: customerData ? customerData.address.country : '',
    address: customerData ? customerData.address.address : '',
    city: customerData ? customerData.address.city : '',
    state: customerData ? customerData.address.state : '',
    zip: customerData ? customerData.address.zip : '',
    telephone: customerData ? customerData.telephone : '',
    PaymentSelected: '-1'
  };
  
  localStorage.setItem(
    'billingInfo',
    JSON.stringify(initialValues)
  );

  const handleShow=(card)=>{setCardDelete(card); setShow(true);}
  const handleClose=()=>setShow(false)


 

  return (
    <div>
      <div className='container'>
        <div>
          <Toaster />
        </div>
        <SubHeader currPage='makeReservation' />

        <div className='row row-padding-top line-bottom-dark'>
          <div className='col-md-8'>
            <h1>Reservation details</h1>
          </div>
          <Timer />
        </div>

        {/* one reservation */}
        {removeFlag && (
          <div className='row'>
            <div className='col-md-8 col-sm-8 col-xs-12'>
              <h4>
                <em>Your reservation is removed</em>
              </h4>
            </div>
          </div>
        )}
        {/* END one reservation */}

        <div className='row line-bottom-dark'>
          <div className='col-xl-2 col-md-2 col-2'></div>
          <div className='col-md-1 col-1'></div>
          <div className='col-xl-2 col-md-3 col-3'></div>
          <div className='col-xl-4 col-md-4 col-3'>
            <p className='less-padding text-end'>
              <strong>Grand total</strong>
            </p>
          </div>
          <div className='col-md-2 col-3 text-end'>
            <p className='less-padding'>
              <strong> ${grandTotal}</strong>
            </p>
          </div>
        </div>
        {reservationData?.map((dataItem, index) => (
          <ReservationEntries
            key={index}
            index={index}
            dataItem={dataItem}
            removeCartItem={removeCartItem}
          />
        ))}
        
        <div className='row'>
          <div className='col-xl-2 col-md-2 col-2'></div>
          <div className='col-md-1 col-1'></div>
          <div className='col-xl-2 col-md-3 col-3'></div>
          <div className='col-xl-4 col-md-4 col-3'>
            <p className='less-padding text-end'>
              <strong>Order Processing Fee</strong>
            </p>
          </div>
          <div className='col-md-2 col-3 text-end'>
            <p className='less-padding'>
              <strong> ${feeTotal}</strong>
            </p>
          </div>
        </div>

        <div className='row'>
          <div className='col-xl-2 col-md-2 col-2'></div>
          <div className='col-md-1 col-1'></div>
          <div className='col-xl-2 col-md-3 col-3'></div>
          <div className='col-xl-4 col-md-4 col-3'>
            <p className='less-padding text-end'>
              <strong>Grand total</strong>
            </p>
          </div>
          <div className='col-md-2 col-3 text-end'>
            <p className='less-padding'>
              <strong> ${grandTotal}</strong>
            </p>
          </div>
        </div>
        <div className='row row-padding'>
          <div className='col-md-12'>
            <Link href='/reservations'>
              <a className='btn btn-primary btn-outline-primary addPadding'>
                Add another reservation
              </a>
            </Link>
            <Link href="/cart-review">
              <a className='btn btn-primary addPadding ml-2'>
                Continue
              </a>
            </Link>
            {/*<a
              className='btn btn-primary ml-2'
              data-toggle='collapse'
              href='#collapsePay'
              role='button'
              aria-expanded='false'
              aria-controls='collapseExample'
            >
              Add billing information
            </a>*/}
          </div>

          <div
            className='collapse'
            id='collapsePay'
            style={{ width: '100%', paddingLeft: '15px', paddingRight: '15px' }}
          >
            <div className='row row-padding-top'>
              <div className='col-12'>
                <h4>Billing information:</h4>
              </div>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={PUCBillingInfoSchema}
              onSubmit={async (values) => {
                const paymentCard =
                  values.PaymentSelected !== '-1'
                    ? PaymentsGroup.find(
                        (payment) =>
                          payment.customerPaymentProfileId ===
                          values.PaymentSelected
                      )
                    : '-1';
                const billingData = {
                  ...values,
                  company: customerData.company,
                  paymentCard,
                };
                localStorage.setItem(
                  'billingInfo',
                  JSON.stringify(billingData)
                );
                router.push('/cart-review');
              }}
            >
              {({
                setFieldValue,
                handleChange,
                values,
                handleBlur,
                touched,
                errors,
              }) => {
                const handleCountryChange = (country) => {
                  setFieldValue('country', country);
                  setFieldValue('state', '');
                };
                 const handleDeleteCard=async()=>{
    try{

      const {data}=await API.delete('/puc/paymentprofile',{
        data:{
          paymentProfileId:cardDelete
        }
      })
      // console.log(data)
      setPaymentsGroup(data.data.paymentProfiles)
      setCardDelete(null)
      setFieldValue('PaymentSelected', '-1');
      handleClose()
    }catch(err){
      console.log(err)
    }
    

  }
                return (
                  <Form>
                    <div className='row'>
                      <div className='col-xl-6 col-md-6'>
                        <label htmlFor='people'>First name*</label>
                        <MyTextInput
                          type='text'
                          name='firstName'
                          className='form-control'
                        />
                      </div>
                      <div className='col-xl-6 col-md-6'>
                        <label htmlFor='people'>Last name*</label>
                        <MyTextInput
                          name='lastName'
                          type='text'
                          className='form-control'
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-xl-4 col-md-4'>
                        <label htmlFor='country'>Country*</label>

                        <CountryDropdown
                          className='form-control'
                          name='country'
                          onChange={handleCountryChange}
                          value={values.country}
                          onBlur={handleBlur}
                          valueType="short"
                        />
                        {touched.country && errors.country && (
                          <div
                            id='feedback'
                            style={{ marginLeft: '0.5rem', color: '#E95F5F' }}
                          >
                            {errors.country}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-xl-8 col-md-8'>
                        <label htmlFor='address'>Address*</label>
                        <MyTextInput
                          type='text'
                          className='form-control'
                          name='address'
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-xl-4 col-sm-2'>
                        <label htmlFor='city'>City*</label>
                        <MyTextInput
                          type='text'
                          className='form-control'
                          name='city'
                        />
                      </div>

                      <div className='col-xl-2 col-sm-2'>
                        <label htmlFor='state'>State*</label>

                        <RegionDropdown
                          className='form-control'
                          name='state'
                          country={values.country}
                          value={values.state}
                          onChange={(_, e) => handleChange(e)}
                          countryValueType="short"
                          valueType="short"
                        />
                        {touched.state && errors.state && (
                          <div
                            id='feedback'
                            style={{ marginLeft: '0.5rem', color: '#E95F5F' }}
                          >
                            {errors.state}
                          </div>
                        )}
                      </div>
                      <div className='col-xl-2 col-sm-2'>
                        <label htmlFor='zip'>Zip*</label>
                        <MyTextInput
                          name='zip'
                          type='text'
                          className='form-control'
                        />
                      </div>
                    </div>

                    <div className='row line-bottom'>
                      <div className='col-xl-6 col-md-6'>
                        <label htmlFor='mobile'>Mobile*</label>

                        <MyPhoneInput
                          name='telephone'
                          className='form-control'
                          formikObj={{
                            values,
                            setFieldValue,
                            handleBlur,
                            errors,
                          }}
                          style={{
                            padding: '5px',
                          }}
                        />
                      </div>
                    </div>

                    {/*<div className='row'>
                      <div className='col-xl-6 col-md-6'>
                        <label for='mobile'>
                          <h4>Payment*</h4>
                        </label>

                        {PaymentsGroup?.map((payment, idx) => (
                          <div className='form-check' key={idx}>
                            <Field
                              type='radio'
                              name='PaymentSelected'
                              value={payment.customerPaymentProfileId}
                              className='form-check-input'
                            />

                            <label className='form-check-label'>
                              {payment.payment.creditCard.icon && (
                                <img
                                  src={payment.payment.creditCard.icon}
                                  width='50'
                                  style={{ marginRight: '8px' }}
                                />
                              )}
                              {payment.payment.creditCard.cardNumber}
                            </label>
                            <button type='button' className='remove_card_btn red' onClick={()=>handleShow(payment.customerPaymentProfileId)}>Remove</button>
                          </div>
                        ))}
                        <div className='form-check'>
                          <Field
                            type='radio'
                            name='PaymentSelected'
                            value='-1'
                            className='form-check-input'
                          />
                          <label className='form-check-label'>
                            Add a new card
                          </label>
                        </div>
                      </div>
                    </div>*/}

                    {/*<div className='row row-padding'>
                      <div className='col-12'>
                        <button className='btn btn-primary' type='submit'>
                          Continue
                        </button>
                      </div>
                    </div>*/}
        <Modal show={show}>
        <Modal.Body className='text-start'>
          <h2>Are you sure you want to remove this card?</h2>
         
        </Modal.Body>
        <Modal.Footer>
          <button

            className='btn btn-primary btn-outline-primary'
            onClick={handleClose}
          >
            No
          </button>
          <button className='btn btn-primary' onClick={handleDeleteCard}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
