import * as Yup from 'yup';

const PUCEmailCopySchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Required'),
  verifyEmail: Yup.string()
    .required('Please confirm your email address')
    .when('email', {
      is: (email) => (email && email.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('email')], "Email doesn't match"),
    }),
});

export default PUCEmailCopySchema;
