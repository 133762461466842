import React from 'react';
import { useField } from 'formik';

export default function MySelect({ ...props }) {
  const [field, meta] = useField(props);
  return (
    <div>
      <select {...field} {...props} />
      {meta.touched && meta.error ? (
        <div
          style={{
            marginLeft: '0.5rem',
            marginBottom: '0.5rem',
            color: '#E95F5F',
          }}
        >{meta.error}</div>
      ) : null}
    </div>
  );
}
