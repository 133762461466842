import React, { useState, useEffect, useContext } from 'react';
import { Loader, PaymentLoader } from 'Atoms/';
import API from 'Services/api';
import ReservationEntries from '../Cart/ReservationEntries';
import Payment from './Payment';
import { Modal } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { SubHeader } from '..';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Timer from 'src/components/Timer';
import { TimerContext } from 'Lib/contexts/TimerContext';
import CancelReservationButton from 'src/components/CancelReservationButton';
import { errorObj } from 'Lib/constants';

export default function Index() {
  const { stopTimer } = useContext(TimerContext);
  const { clearTimer, getDeadTime } = useContext(TimerContext);
  const [loading, setLoading] = useState(true);
  const [billingInfo, setBillingInfo] = useState('');
  const [grandTotal, setGrandTotal] = useState('');
  const [feeTotal, setFeeTotal] = useState('');
  const [reservationData, setReservationData] = useState('');
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [terms, setTerms] = useState(false);
  const [paymentFail, setPaymentFail] = useState(null);
  const router = useRouter();
  useEffect(() => {
    if (localStorage.getItem('billingInfo')) {
      setLoading(false);
      setBillingInfo(JSON.parse(localStorage.getItem('billingInfo')));
    }
    async function apiHit() {
      try {
        const { data } = await API.get('/puc/getCart');
        // let accept = document.createElement('script');
        // accept.setAttribute('src', process.env.NEXT_PUBLIC_PG_URL);
        // document.body.appendChild(accept);
        // if (process.client) {
        //   window.checkout = this.checkout;
        // }
        
        if(!data.data.reservationData.length) {
          stopTimer();
          toast('Shopping cart is empty', errorObj);
          router.push('/reservations');
        }
        
        setGrandTotal(data?.data?.orderTotal);
        setReservationData(data?.data?.reservationData);
        setFeeTotal(data?.data?.addOn);
      } catch (error) {
        console.warn(error);
      }
    }
    apiHit();
  }, []);

  if (!grandTotal || !reservationData) {
    return <Loader />;
  }

  const {
    firstName,
    lastName,
    country,
    address,
    city,
    state,
    zip,
    telephone,
    company,
    PaymentSelected,
    paymentCard,
  } = billingInfo;
  
  const handlePay = async () => {
    handleShow();
    setPaymentLoading(true);
    
    const obj = {
      dataValue: '-',
      dataDescriptor: '-',
      orderTotal: grandTotal,
      customerData: {
        ...billingInfo,
        saveCustomerProfile: undefined,
        PaymentSelected: undefined,
      },
      paymentProfileId: PaymentSelected,
      origin: 'puc'
    };
    
    try {
      const { data } = await API.post('/puc/payment', obj);
      
      if (data.message === "Success") {
        localStorage.removeItem('billingInfo');
        localStorage.setItem('paymentData', JSON.stringify(data));
        stopTimer();
        //router.push('/confirmation');
        //handleClose();
        
        router.push(data.data.checkoutUrl);
      }
      else {
        handlePaymentError('Payment Service Error');
      }
    } catch (error) {
      handlePaymentError('Payment Service Error');
    }
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handlePaymentError = (msg) => setPaymentFail(msg);

  const ErrorModal = () => (
    <div>
      {paymentFail}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '2em',
        }}
      >
        <button
          type='button'
          className='btn btn-primary'
          onClick={handleClose}
        >
          OK
        </button>
      </div>
    </div>
  );

  const handleCancel = async () => {
    await API.delete('/puc/delete');
    stopTimer();
    router.push('/reservations');
  };

  const handlePaymentCard = async () => {
    handleShow();
    setPaymentLoading(true);

    const obj = {
      dataValue: '-',
      dataDescriptor: '-',
      orderTotal: grandTotal,
      customerData: {
        ...billingInfo,
        saveCustomerProfile: undefined,
        PaymentSelected: undefined,
      },
      paymentProfileId: PaymentSelected,
    };
    try {
      const { data } = await API.post('/puc/payment', obj);
      // console.log(data);
      if (data.message === 'Success') {
        localStorage.removeItem('billingInfo');
        localStorage.setItem('paymentData', JSON.stringify(data));
        stopTimer();
        router.push('/confirmation');
        handleClose();
      }
    } catch (error) {
      console.log(error.response);

      handlePaymentError('Payment Service Error');
    }
  };

  return (
    <div>
      <div>
        <Toaster />
      </div>
      <div className='container'>
        <SubHeader currPage='makeReservation' />

        <div className='row row-padding-top line-bottom-dark'>
          <div className='col-md-8'>
            <h1>Reservation details: review</h1>
          </div>
          <Timer />
        </div>
        <div className='row line-bottom-dark'>
          <div className='col-xl-2 col-md-2 col-2'></div>
          <div className='col-md-1 col-1'></div>
          <div className='col-xl-2 col-md-3 col-3'></div>
          <div className='col-xl-4 col-md-4 col-3'>
            <p className='less-padding text-end'>
              <strong>Grand total</strong>
            </p>
          </div>
          <div className='col-md-2 col-3 text-end'>
            <p className='less-padding'>
              <strong> ${grandTotal}</strong>
            </p>
          </div>
        </div>
        {reservationData?.map((dataItem, index) => (
          <ReservationEntries
            key={index}
            index={index}
            dataItem={dataItem}
            edit={false}
          />
        ))}
        
        <div className='row'>
          <div className='col-xl-2 col-md-2 col-2'></div>
          <div className='col-md-1 col-1'></div>
          <div className='col-xl-2 col-md-3 col-3'></div>
          <div className='col-xl-4 col-md-4 col-3'>
            <p className='less-padding text-end'>
              <strong>Order Processing Fee</strong>
            </p>
          </div>
          <div className='col-md-2 col-3 text-end'>
            <p className='less-padding'>
              <strong> ${feeTotal}</strong>
            </p>
          </div>
        </div>

        <div className='row'>
          <div className='col-xl-2 col-md-2 col-2'></div>
          <div className='col-md-1 col-1'></div>
          <div className='col-xl-2 col-md-3 col-3'></div>
          <div className='col-xl-4 col-md-4 col-3'>
            <p className='less-padding text-end'>
              <strong>Grand total</strong>
            </p>
          </div>
          <div className='col-md-2 col-3 text-end'>
            <p className='less-padding'>
              <strong> ${grandTotal}</strong>
            </p>
          </div>
        </div>

       {/* <div className='row row-padding-bottom line-bottom-dark'>
          <div className='col-md-12'>
            <h3>Billing information:</h3>

            <p>
              <strong>Name:</strong> {firstName} {lastName}
            </p>
            <p>
              <strong>Address:</strong> {address}, {city}, {state}, {zip}
            </p>
            <p>
              <strong>Mobile:</strong> {telephone}
            </p>
          </div>
        </div>*/}

        <div className='row'>
          <div className='col-xl-12 col-12'>
            <p>
              <span className='red'>
                Partial refunds will be offered if cancelled more than three
                (3) days in advance.
              </span>{' '}
              Modifications will be for parking and/or entry reservation times,
              space available basis and done at least 3 days in advance
              (additional fees may apply, partial refunds may or may not apply).
            </p>
            <p>
              <span className='red'>We are not responsible</span> for
              last-minute closures of the park due to weather, safety concerns,
              road closures, or trail closures from any cause whatsoever. Full
              or partial refunds may be considered but will be at the sole
              discretion of the Hawaii State Parks Division.
            </p>
            <div className='form-check'>
              <input
                className='form-check-input'
                type='checkbox'
                value={terms}
                onChange={() => setTerms((term) => !term)}
                id='flexCheckDefault'
              />
              <label className='form-check-label' htmlFor='flexCheckDefault'>
                Please check here that you agree with all terms listed above.
              </label>
            </div>
          </div>
        </div>

        <div className='row row-padding'>
          <div className='col-md-12'>
            {/* <Link href='/reservations'>
              <a className='btn btn-primary btn-outline-primary'>Cancel</a>
            </Link> */}
            <button
              className='btn btn-primary btn-outline-primary'
              onClick={handleCancel}
            >
              Cancel
            </button>
            <Link href='/cart'>
              <a className='btn btn-primary btn-outline-primary ml-2'>
                Edit detail
              </a>
            </Link>

            <button
              type='button'
              className='btn btn-primary ml-2'
              onClick={handlePay}
              disabled={!terms}
              style={{ background: '#447969' }}
            >
              Pay
            </button>

            <Modal show={show} onHide={handleClose}>
              <Modal.Body>
                {paymentLoading ? (
                  !paymentFail ? (
                    <PaymentLoader />
                  ) : (
                    <ErrorModal />
                  )
                ) : (
                  <Payment
                    setPaymentLoading={setPaymentLoading}
                    handleClose={handleClose}
                    handlePaymentError={handlePaymentError}
                    grandTotal={grandTotal}
                  />
                )}
              </Modal.Body>
            </Modal>

            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}
