import { MyTextInput } from 'Atoms/Formik';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { useRouter } from 'next/router';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import API from 'Services/api';
import { SubHeader } from '..';
import Entries from './Entries';
import { errorObj } from 'Lib/constants';

export default function Index() {
  const [orderData, setOrderData] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [sortModes, setSortModes] = useState({
    orderNo: 'asc',
    date: 'asc',
    amount: 'asc',
  });

  const router = useRouter();

  /**
   * @param {string} field The field to sort by. Must be one of the keys in
   * an object of `orderData`.
   * @description Sorts `orderData` by `field`, and updates the sortMode for
   * that field.
   */
  function sortOrders(field) {
    const _orders = orderData;
    if (sortModes[field] === 'asc') {
      // Sort amount after removing "$"
      if (field === 'amount')
        _orders.sort((a, b) =>
          parseFloat(a[field].slice(1)) > parseFloat(b[field].slice(1))
            ? 1
            : -1,
        );
      else _orders.sort((a, b) => (a[field] > b[field] ? 1 : -1));
    } else {
      if (field === 'amount')
        _orders.sort((a, b) =>
          parseFloat(a[field].slice(1)) < parseFloat(b[field].slice(1))
            ? 1
            : -1,
        );
      else _orders.sort((a, b) => (a[field] < b[field] ? 1 : -1));
    }

    setOrderData(_orders);
    setSortModes({
      ...sortModes,
      [field]: sortModes[field] === 'asc' ? 'desc' : 'asc',
    });
  }

  return (
    <>
      <div className='container'>
        <SubHeader currPage='searchReservation' />
        <div className='row row-padding'>
          <div className='col-lg-12 col-md-12 col-12'>
            <div className='row'>
              <div className='col-12'>
                <h2>Search reservations</h2>
              </div>
            </div>
            <Formik
              initialValues={{ order_no: '' }}
              onSubmit={async ({ order_no }) => {
                try {
                  const { data } = await API.get('/puc/search', {
                    params: {
                      orderNo: order_no,
                      startDate: startDate
                        ? moment(startDate).format('YYYY-MM-DD')
                        : '',
                      endDate: endDate
                        ? moment(endDate).format('YYYY-MM-DD')
                        : '',
                    },
                  });

                  setOrderData(data?.data);
                  // console.log(data?.data);
                } catch (error) {
                  setOrderData(null);

                  toast(error.response.data.error, errorObj);
                }
              }}
            >
              {(props) => {
                const handleDateChange = (dates) => {
                  const [start, end] = dates;
                  setStartDate(start);
                  setEndDate(end);
                };
                return (
                  <Form>
                    <div className='row row-padding-top'>
                      <div className='col-md-3 col-sm-3 col-xs-12'>
                        <label>Order #</label>
                      </div>
                      <div className='col-md-3 col-sm-3 col-xs-12'>
                        <MyTextInput
                          name='order_no'
                          type='text'
                          className='form-control'
                        />
                        <p style={{ margin: '1.2rem 0' }}>Or</p>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-3 col-sm-3 col-xs-12'>
                        <label>Date of reservation</label>
                      </div>
                      <div className='col-md-4 col-sm-4 col-xs-12'>
                        <div className='input-group input-daterange'>
                          <DatePicker
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            onChange={handleDateChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className='row row-padding-bottom'
                      // style="padding-top: 1.5rem;"
                      style={{ paddingTop: '1.5rem' }}
                    >
                      <div className='col-md-6 col-sm-6 col-xs-12'>
                        <button
                          type='submit'
                          className='btn btn-primary'
                          data-toggle='collapse'
                          href='#collapseSearch'
                          aria-expanded='false'
                          aria-controls='collapseExample'
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
            <div
              className='collapse'
              id='collapseSearch'
              // style="width: 100%; padding-left: 15px; padding-right: 15px;"
              style={{
                width: '100%',
                paddingLeft: '15px',
                paddingRight: '15px',
              }}
            >
              <div className='row'>
                <div className='col-12'>
                  <h2>Search results</h2>
                </div>
              </div>
              <div className='row line-bottom'>
                <div className='col-xl-3 col-md-3 col-3'>
                  <p
                    className='less-padding'
                    role='button'
                    onClick={() => sortOrders('orderNo')}
                  >
                    <strong>Order #</strong>
                  </p>
                </div>
                <div className='col-md-2 col-3'>
                  <p
                    className='less-padding'
                    role='button'
                    onClick={() => sortOrders('date')}
                  >
                    <strong>Date</strong>
                  </p>
                </div>
                <div className='col-2 text-end'>
                  <p
                    className='less-padding'
                    role='button'
                    onClick={() => sortOrders('amount')}
                  >
                    <strong>Amount</strong>
                  </p>
                </div>
                <div className='col-5 '>
                  <p className='less-padding'>
                    <strong></strong>
                  </p>
                </div>
              </div>

              {orderData &&
                orderData.map((order, index) => {
                  return <Entries key={index} dataItem={order} />;
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
