import { Loader } from 'Atoms/';
import { useEffect, useState } from 'react';
import API from 'Services/api';
import { SubHeader } from '..';
import Entries from './Entries';

export default function Index() {
  const [orders, setOrders] = useState('');
  const [sortModes, setSortModes] = useState({
    orderNo: 'asc',
    dateOfReservation: 'asc',
    totalPrice: 'asc',
  });

  useEffect(() => {
    (async function () {
      const { data } = await API.get('/puc/getReservation', {
        params: {
          sortBy: 'dateOfReservation',
        },
      });
      if (data?.message === 'Success') setOrders(data?.data);
    })();
  }, []);

  if (!orders) {
    return <Loader />;
  }

  /**
   * @param {string} field The field to sort by. Must be one of the keys in
   * an object of `orders`.
   * @description Sorts `orders` by `field`, and updateOfReservations the sortMode for
   * that field.
   */
  function sortOrders(field) {
    const _orders = orders;
    if (sortModes[field] === 'asc') {
      // Sort totalPrice after removing "$"
      if (field === 'totalPrice')
        _orders.sort((a, b) =>
          parseFloat(a[field]) > parseFloat(b[field]) ? 1 : -1,
        );
      else _orders.sort((a, b) => (a[field] > b[field] ? 1 : -1));
    } else {
      if (field === 'totalPrice')
        _orders.sort((a, b) =>
          parseFloat(a[field]) < parseFloat(b[field]) ? 1 : -1,
        );
      else _orders.sort((a, b) => (a[field] < b[field] ? 1 : -1));
    }

    setOrders(_orders);
    setSortModes({
      ...sortModes,
      [field]: sortModes[field] === 'asc' ? 'desc' : 'asc',
    });
  }

  return (
    <div>
      <div className='container'>
        <SubHeader currPage='allReservation' />

        <div className='row row-padding-top'>
          <div className='col-lg-12 col-md-12 col-12'>
            <h2>All reservations</h2>
          </div>
        </div>
        <div className='row line-bottom'>
          <div className='col-xl-3 col-md-3 col-3'>
            <p
              className='less-padding'
              role='button'
              onClick={() => sortOrders('orderNo')}
            >
              <strong>Order #</strong>
            </p>
          </div>
          <div className='col-2'>
            <p
              className='less-padding'
              role='button'
              onClick={() => sortOrders('dateOfReservation')}
            >
              <strong>Date</strong>
            </p>
          </div>
          <div className='col-2 text-end'>
            <p
              className='less-padding'
              role='button'
              onClick={() => sortOrders('totalPrice')}
            >
              <strong>Amount</strong>
            </p>
          </div>
          <div className='col-5 '>
            <p className='less-padding'>
              <strong></strong>
            </p>
          </div>
        </div>
        {orders?.map((dataItem, index) => (
          <Entries key={index} dataItem={dataItem} />
        ))}
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}
