import React, { Fragment, useEffect, useState } from 'react';
import { SubHeader } from '..';
import { Formik, Form } from 'formik';
import { MyTextInput } from 'Atoms/Formik';
import { PUCEmailCopySchema } from 'Lib/validators';
import API from 'Services/api';
import toast, { Toaster } from 'react-hot-toast';
import Link from 'next/link';

export default function Index() {
  const [res, setRes] = useState(null);
  useEffect(() => {
    try {
      const paymentData = localStorage.getItem('paymentData');

      if (paymentData) {
        const parsedPay = JSON.parse(paymentData);
        setRes([parsedPay.data]);
      }
    } catch (err) {
      console.log(err);
      // redirect to cart
    }
  }, []);

  const handleDownload = async (orderNo, reservationId) => {
    const { data } = await API.get('/puc/downloadTicket', {
      params: { reservationId },
      responseType: 'blob',
    });

    const url = URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    // link.download = orderNo + '.pdf';
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    link.setAttribute('download', 'PUC Reservation');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <div className='container'>
        <div>
          <Toaster />
        </div>
        <SubHeader currPage='makeReservation' />
        <div className='row row-padding'>
          <div className='col-md-12'>
            <h1>Your order has been successfully processed!</h1>
            <p>
              The reservation confirmation(s) have been sent to{' '}
              {res && res[0].customerData.email}. To view your reservation QR code(s), please click on the ticket # link below.
              <br />
              <em>
                There is limited cellular connectivity at the Park. You MUST
                download or print the ticket prior to arrival.
              </em>
            </p>
          </div>
        </div>

        {!!res
          ? res.map(({ customerData, cartData, reservationData }, i) => (
              <Fragment key={i}>
                <div className='row line-bottom'>
                  <div className='col-xl-3 col-md-3 col-3'>
                    <p className='less-padding'>
                      <strong>Ticket</strong>
                    </p>
                  </div>
                  <div className='col-xl-1 col-md-2 col-1'>
                    <p className='less-padding'>
                      <strong>Qty</strong>
                    </p>
                  </div>
                  <div className='col-xl-2 col-md-2 col-3'>
                    <p className='less-padding'>
                      <strong>Date</strong>
                    </p>
                  </div>
                  <div className='col-xl-3 col-md-4 col-3'>
                    <p className='less-padding'>
                      <strong>Time</strong>
                    </p>
                  </div>
                  <div className='col-2 text-end'>
                    <p className='less-padding'>
                      <strong>Price</strong>
                    </p>
                  </div>
                </div>

                {reservationData.map((reservation, i) => (
                  <div key={i} className='row line-bottom'>
                    <div className='col-xl-3 col-md-3 col-3'>
                      <p className='less-padding'>
                        <Link href={`reservation-confirmation/${reservation._id}`}>
                          <a>{reservation.orderNo}</a>
                        </Link>
                      </p>
                    </div>
                    <div className='col-xl-1 col-md-2 col-1'>
                      <p className='less-padding'>{(reservation.quantity ? reservation.quantity : 'NA')}</p>
                    </div>
                    <div className='col-xl-2 col-md-2 col-3'>
                      <p className='less-padding'>{reservation.dateOfReservation}</p>
                    </div>
                    <div className='col-xl-3 col-md-4 col-3'>
                      <p className='less-padding'>{(reservation.timeslots.length ? reservation.timeslots[0].label : 'NA')}</p>
                    </div>
                    <div className='col-2 text-end'>
                      <p className='less-padding'>${reservation.totalPrice}</p>
                    </div>
                  </div>
                ))}

                <div className='row line-bottom'>
                  <div className='col-xl-3 col-md-3 col-3'></div>
                  <div className='col-md-1 col-2'></div>
                  <div className='col-xl-2 col-md-3 col-2'></div>
                  <div className='col-xl-3 col-md-4 col-3'>
                    <p className='less-padding text-end'>Total</p>
                  </div>
                  <div className='col-2 text-end'>
                    <p className='less-padding'>${cartData.orderTotal}</p>
                  </div>
                </div>

                <div className='row line-bottom'>
                  <div className='col-xl-8 col-10'>
                    <div className='row'>
                      <div className='col-xl-6 col-12'>
                        <p
                          className='less-padding'
                          style={{ paddingTop: '.2rem' }}
                        >
                          <strong>Customer details:</strong>
                        </p>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-xl-3 col-5'>
                        <p className='less-padding'>Company:</p>
                      </div>
                      <div className='col-xl-5 col-7'>
                        <p className='less-padding'>{customerData.company}</p>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-xl-3 col-5'>
                        <p className='less-padding'>Name:</p>
                      </div>
                      <div className='col-xl-5 col-7'>
                        <p className='less-padding'>{customerData.name}</p>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-xl-3 col-5'>
                        <p className='less-padding'>Email:</p>
                      </div>
                      <div className='col-xl-5 col-7'>
                        <p className='less-padding'>{customerData.email}</p>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-xl-3 col-5'>
                        <p className='less-padding'>Telephone:</p>
                      </div>
                      <div className='col-xl-5 col-7'>
                        <p className='less-padding'>{customerData.mobile}</p>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-xl-3 col-5'>
                        <p className='less-padding'>PUC License #:</p>
                      </div>
                      <div className='col-xl-5 col-7'>
                        <p className='less-padding'>
                          {customerData.pucLicense}
                        </p>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-xl-3 col-5'>
                        <p className='less-padding'>Address:</p>
                      </div>
                      <div className='col-xl-5 col-7'>
                        <p className='less-padding'>
                          {customerData.address}
                          <br />
                          {`${customerData.city} ${customerData.stateProvince} ${customerData.zip}`}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-xl-12 col-md-12'>
                    <h3>Email copy of this ticket</h3>
                  </div>
                </div>
                <Formik
                  initialValues={{ email: '', verifyEmail: '' }}
                  validationSchema={PUCEmailCopySchema}
                  onSubmit={async ({ email }, { resetForm }) => {
                    // console.log(email);
                    try {
                      const { data } = await API.post(
                        `reservations/${reservationData._id}/email`,
                        {
                          email,
                        }
                      );
                      // console.log(data);
                      if (data?.message === 'Success') {
                        // console.log(data);
                        toast.success(`A copy has been delivered to ${email}`);
                        resetForm();
                      }
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                >
                  {(props) => {
                    return (
                      <Form
                        className='row line-bottom-dark'
                        style={{ paddingBottom: '2.5rem' }}
                      >
                        <div className='col-xl-5 col-md-5'>
                          <label htmlFor='email'>Email*</label>
                          <MyTextInput
                            name='email'
                            type='email'
                            className='form-control'
                          />
                        </div>

                        <div className='col-xl-5 col-md-5'>
                          <label htmlFor='verifyEmail'>Verify Email*</label>
                          <MyTextInput
                            name='verifyEmail'
                            type='email'
                            className='form-control'
                          />
                        </div>

                        <div
                          className='col-xl-2 col-md-2'
                          style={{ paddingTop: '35px' }}
                        >
                          <button type='submit' className='btn btn-primary'>
                            Send
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </Fragment>
            ))
          : 'Loading...'}

        <br />
        <br />
        <br />
      </div>
    </div>
  );
}
