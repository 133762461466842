import React, { useEffect, useState } from 'react';
import { Loader } from 'Atoms/';
import Link from 'next/link';
import API from 'Services/api';
import { useRouter } from 'next/router';
import toast, { Toaster } from 'react-hot-toast';
import { errorObj } from 'Lib/constants';

const Index = () => {
  let router = useRouter();

  const [registerData, setRegisterData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem('puc_register_data')) {
      router.push('/');
    } else {
      setLoading(true);
      const registerDataParsed = JSON.parse(
        localStorage.getItem('puc_register_data')
      );
      setRegisterData(registerDataParsed);
    }
  }, []);

  if (!loading) {
    return <Loader />;
  }

  const {
    company,
    yourName,
    email,
    telephone,
    pucLicense,
    address,
    city,
    state,
    zip,
    islands,
  } = registerData;

  const handleClick = async () => {
    try {
      const { data } = await API.post('/puc/register', {
        company,
        name: yourName,
        email,
        pucLicense,
        telephone,
        address,
        city,
        state,
        zip,
        host: process.env.NEXT_PUBLIC_WEB_URL,
        islands,
      });

      if (data.message === 'Created') {
        toast.success(data?.data?.message);
        router.push('/registration-setup');
      }
    } catch (err) {
      toast(err.response.data.data.message, errorObj);
    }
  };

  return (
    <div className='container'>
      <Toaster />
      <div className='row row-padding'>
        <div className='col-xl-8 col-md-8'>
          <h2>Review details:</h2>

          <div className='row line-bottom'>
            <div className='col-xl-5 col-5'>
              <p className='less-padding'>Company:</p>
            </div>
            <div className='col-xl-7 col-7'>
              <p className='less-padding'>{company}</p>
            </div>
          </div>
          <div className='row line-bottom'>
            <div className='col-xl-5 col-5'>
              <p className='less-padding'>Your name:</p>
            </div>
            <div className='col-xl-7 col-7'>
              <p className='less-padding'>{yourName}</p>
            </div>
          </div>
          <div className='row line-bottom'>
            <div className='col-xl-5 col-5'>
              <p className='less-padding'>Email:</p>
            </div>
            <div className='col-xl-7 col-7'>
              <p className='less-padding'>{email}</p>
            </div>
          </div>
          <div className='row line-bottom'>
            <div className='col-xl-5 col-5'>
              <p className='less-padding'>Telephone:</p>
            </div>
            <div className='col-xl-7 col-7'>
              <p className='less-padding'>{telephone}</p>
            </div>
          </div>
          <div className='row line-bottom'>
            <div className='col-xl-5 col-5'>
              <p className='less-padding'>PUC license #</p>
            </div>
            <div className='col-xl-7 col-7'>
              <p className='less-padding'>{pucLicense}</p>
            </div>
          </div>
          <div className='row line-bottom'>
            <div className='col-xl-5 col-5'>
              <p className='less-padding'>Address:</p>
            </div>
            <div className='col-xl-7 col-7'>
              <p className='less-padding'>
                {address}
                <br />
                {city}, {state} {zip} USA
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col-xl-5 col-5'>
              <p className='less-padding'>Islands you operate on:</p>
            </div>
            <div className='col-xl-7 col-7'>
              <p className='less-padding'>
                {islands.map((island, idx) =>
                  idx + 1 !== islands.length ? `${island}, ` : island
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='row row-padding'>
        <div className='col-md-6 col-sm-6 col-xs-12'>
          <Link href='/registration'>
            <a className='btn btn-primary btn-outline-primary'>Back</a>
          </Link>
          <button
            //Link
            className='btn btn-primary ml-2'
            onClick={() => handleClick()}
          >
            Confirm
          </button>
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default Index;
