import React, { useEffect, useState } from 'react';
import API from 'Services/api';
import { SubHeader } from '..';
import { Formik, Form } from 'formik';
import { MyTextInput } from 'Atoms/Formik';
import { PUCEmailCopySchema } from 'Lib/validators';
import toast, { Toaster } from 'react-hot-toast';

function index({ orderId }) {
  const [ticketData, setTicketData] = useState(null);
  useEffect(() => {
    const apiHit = async () => {
      try {
        const { data } = await API.get(`/reservations/${orderId}/details`);
        console.log(data?.data);
        // console.log(orderId);
        setTicketData(data?.data);
      } catch (err) {
        console.log(err);
      }
    };
    apiHit();
  }, []);

  const handleDownload = async () => {
    const { data } = await API.get('/puc/downloadTicket', {
      params: { reservationId: orderId },
      responseType: 'blob',
    });

    const url = URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    // link.download = ticketData.reservationData.orderNo + '.pdf';
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    link.setAttribute('download', orderId);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div className='container'>
        <div>
          <Toaster />
        </div>
        <SubHeader currPage='searchReservation' />
        <div className='row row-padding'>
          <div className='col-md-12'>
            <h1>Your reservation</h1>
            <p>
              A copy of this confirmation has been sent to{' '}
              {ticketData && ticketData.customerData.email}.
              <br />
              <em>
                There is limited cellular connectivity at the Park. You MUST
                download or print the ticket prior to arrival.
              </em>
            </p>
          </div>
        </div>
        {!!ticketData ? (
          <>
            <div className='row'>
              <div className='col-xl-2 col-4'>
                <img
                  src={
                    ticketData.reservationData.QRLink
                      ? ticketData.reservationData.QRLink
                      : '../images/qr-code.png'
                  }
                  className='img-fluid'
                />
              </div>
              <div className='col-md-7 col-8'>
                <h3 style={{ margin: '0.5rem 0px 1.5rem 0px' }}>
                  Order #{ticketData.reservationData.orderNo}
                </h3>
                <p style={{ paddingTop: '.2rem' }}>
                  <strong>Your one time QR code for entry is to the left</strong>
                </p>
              </div>
              <div
                className='col-md-3 col-12 text-end'
                style={{ paddingBottom: '1.5rem' }}
              >
                {/* <a
                  className='btn btn-primary'
                  href='../registration-puc.pdf'
                  target='_blank'
                >
                  Download ticket
                </a> */}
                <button className='btn btn-primary' onClick={handleDownload}>
                  Download ticket
                </button>
              </div>
            </div>

            <div className='row line-bottom'>
              <div className='col-xl-3 col-md-3 col-3'>
                <p className='less-padding'>
                  <strong>Ticket</strong>
                </p>
              </div>
              <div className='col-xl-1 col-md-2 col-1'>
                <p className='less-padding'>
                  <strong>Qty</strong>
                </p>
              </div>
              <div className='col-xl-2 col-md-2 col-3'>
                <p className='less-padding'>
                  <strong>Date</strong>
                </p>
              </div>
              <div className='col-xl-2 col-md-2 col-2'>
                <p className='less-padding'>
                  <strong>Time</strong>
                </p>
              </div>
              <div className='col-2 text-end'>
                <p className='less-padding'>
                  <strong>Price</strong>
                </p>
              </div>
            </div>

            {ticketData.pricingDetails.pricingDetails.map((price, i) => (
              <div key={i} className='row line-bottom'>
                <div className='col-xl-3 col-md-3 col-3'>
                  <p className='less-padding'>{price.ticket}</p>
                </div>
                <div className='col-xl-1 col-md-2 col-1'>
                  <p className='less-padding'>{price.qty}</p>
                </div>
                <div className='col-xl-2 col-md-2 col-3'>
                  <p className='less-padding'>{price.date}</p>
                </div>
                <div className='col-xl-2 col-md-2 col-2'>
                  <p className='less-padding'>{price.time}</p>
                </div>
                <div className='col-2 text-end'>
                  <p className='less-padding'>${price.price}</p>
                </div>
              </div>
            ))}

            <div className='row line-bottom'>
              <div className='col-xl-3 col-md-3 col-3'></div>
              <div className='col-md-1 col-2'></div>
              <div className='col-xl-2 col-md-3 col-2'></div>
              <div className='col-xl-2 col-md-3 col-2'>
                <p className='less-padding text-end'>Total</p>
              </div>
              <div className='col-2 text-end'>
                <p className='less-padding'>
                  ${ticketData.pricingDetails.total}
                </p>
              </div>
            </div>

            {ticketData.customerData && (
              <div className='row line-bottom'>
                <div className='col-xl-8 col-10'>
                  <div className='row'>
                    <div className='col-xl-6 col-12'>
                      <p
                        className='less-padding'
                        style={{ paddingTop: '.2rem' }}
                      >
                        <strong>Customer details:</strong>
                      </p>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-xl-3 col-5'>
                      <p className='less-padding'>Company:</p>
                    </div>
                    <div className='col-xl-5 col-7'>
                      <p className='less-padding'>
                        {ticketData.customerData.company}
                      </p>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-xl-3 col-5'>
                      <p className='less-padding'>Name:</p>
                    </div>
                    <div className='col-xl-5 col-7'>
                      <p className='less-padding'>
                        {`${ticketData.customerData.firstName} ${ticketData.customerData.lastName}`}
                      </p>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-xl-3 col-5'>
                      <p className='less-padding'>Email:</p>
                    </div>
                    <div className='col-xl-5 col-7'>
                      <p className='less-padding'>
                        {ticketData.customerData.email}
                      </p>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-xl-3 col-5'>
                      <p className='less-padding'>Telephone:</p>
                    </div>
                    <div className='col-xl-5 col-7'>
                      <p className='less-padding'>
                        {ticketData.customerData.mobile}
                      </p>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-xl-3 col-5'>
                      <p className='less-padding'>PUC License #:</p>
                    </div>
                    <div className='col-xl-5 col-7'>
                      <p className='less-padding'>
                        {ticketData.customerData.pucLicense}
                      </p>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-xl-3 col-5'>
                      <p className='less-padding'>Address:</p>
                    </div>
                    <div className='col-xl-5 col-7'>
                      <p className='less-padding'>
                        {ticketData.customerData.address}
                        <br />
                        {`${ticketData.customerData.city} ${ticketData.customerData.stateProvince} ${ticketData.customerData.zip}`}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className='row'>
              <div className='col-xl-12 col-md-12'>
                <h3>Email copy of this ticket</h3>
              </div>
            </div>

            <Formik
              initialValues={{ email: '', verifyEmail: '' }}
              validationSchema={PUCEmailCopySchema}
              onSubmit={async ({ email }, { resetForm }) => {
                // console.log(email);
                try {
                  const { data } = await API.post(
                    `reservations/${orderId}/email`,
                    {
                      email,
                    }
                  );
                  if (data?.message === 'Success') {
                    // console.log(data);
                    toast.success(`A copy has been delivered to ${email}`);
                    resetForm();
                  }
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              {(props) => {
                return (
                  <Form className='row k' style={{ paddingBottom: '3.5rem' }}>
                    <div className='col-xl-5 col-md-5'>
                      <label htmlFor='email'>Email*</label>
                      <MyTextInput
                        name='email'
                        type='email'
                        className='form-control'
                      />
                    </div>

                    <div className='col-xl-5 col-md-5'>
                      <label htmlFor='verifyEmail'>Verify Email*</label>
                      <MyTextInput
                        name='verifyEmail'
                        type='email'
                        className='form-control'
                      />
                    </div>

                    <div
                      className='col-xl-2 col-md-2'
                      style={{ paddingTop: '35px' }}
                    >
                      <button type='submit' className='btn btn-primary'>
                        Send
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </>
        ) : (
          'Loading...'
        )}
      </div>
    </>
  );
}

export default index;
