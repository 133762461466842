import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { Notices } from 'Molecules/';

const Index = ({ currPage }) => {
  const [parkData, setParkData] = useState("");
  
  useEffect(() => {
    if(localStorage.getItem('parkData')) {
      setParkData(JSON.parse(localStorage.getItem('parkData')))
    }
  }, []);
  
  return (
    <div className='row row-padding-top'>
      <Notices park={parkData._id} />
      <div className='col-12'>
        <span className='showMobile'>
          <p
            //   style='margin-top: -10px;'
            style={{ marginTop: '-10px' }}
          >
            <strong>Manage reservations:</strong>
          </p>
        </span>
        <ul className='nav nav-tabs'>
          <li className='nav-item'>
            <Link href='/reservations'>
              <a
                className={`nav-link ${
                  currPage == 'makeReservation' && 'active'
                }`}
                aria-current='page'
              >
                <span className='Hide'>Make a Reservation</span>
                <span className='showMobile'>New</span>
              </a>
            </Link>
          </li>
          <li className='nav-item'>
            <Link href='/search-reservations'>
              <a
                className={`nav-link ${
                  currPage == 'searchReservation' && 'active'
                }`}
                aria-current='page'
              >
                Search<span className='Hide'> Reservations</span>
              </a>
            </Link>
          </li>
          <li className='nav-item'>
            <Link href='/all-reservations'>
              <a
                className={`nav-link ${
                  currPage == 'allReservation' && 'active'
                }`}
                aria-current='page'
              >
                All<span className='Hide'> Reservations</span>
              </a>
            </Link>
          </li>
          <li className='nav-item' style={{ position: 'absolute', right: 0 }}>
            <Link href='/cart'>
              <a className='nav-link'>
                <span className='Hide'>Shopping</span> Cart
              </a>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default Index;
