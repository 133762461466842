import { createContext, useState } from 'react';

const OrderContext = createContext();
const UserContext = createContext();

const OrderProvider = ({ children }) => {
  const [currentOrder, setCurrentOrder] = useState({});
  const [inputData, setInputData] = useState('');
  const [customerFormData, setCustomerFormData] = useState();

  const [isLogin, setIsLogin] = useState(false);
  return (
    <OrderContext.Provider
      value={{
        customerFormData,
        setCustomerFormData,
        currentOrder,
        setCurrentOrder,
        inputData,
        setInputData,
        isLogin,
        setIsLogin,
      }}
    >
      <UserContext.Provider value={{ isLogin, setIsLogin }}>
        {children}
      </UserContext.Provider>
    </OrderContext.Provider>
  );
};

export { OrderContext, OrderProvider, UserContext };
