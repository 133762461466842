import moment from 'moment';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import API from 'Services/api';

import { Loader, PaymentLoader } from "Atoms/";

export default function Entries({ dataItem }) {
  const [refundLoading, setRefundLoading] = useState(false);
  const [show, setShow] = useState(false);
  // console.log(dataItem);

  const router = useRouter();

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const { orderNo, dateOfReservation, totalPrice, _id, buttonFlags } = dataItem;
  const { cancel, change } = buttonFlags;
  
  const openRefundLoading = () => setRefundLoading(true);
  const closeRefundLoading = () => setRefundLoading(false);

  const handleCancelReservation = async () => {
    try {
      openRefundLoading();
      
      const { data } = await API.post(`/reservations/cancel/visitor`, {
        reservationId: _id,
      })
      
      if (data.message === "Success") {
        document.getElementById("cancelmodel-closeAlt").click();
        localStorage.setItem('cancelReservation', JSON.stringify(data.data));
        router.push('/cancel-confirmation');
        closeRefundLoading();
      }
      
      /*const { data } = await API.post('/reservations/cancel', {
        reservationId: _id,
      });

      localStorage.setItem('cancelReservation', JSON.stringify(data.data));
      router.push('/cancel-confirmation');*/
      
      
    } catch (err) {
      console.log(err);
    }
  };
  const currDate = new Date();
  const myDate = new Date(dateOfReservation);

  const handleChangeReserv = () => router.push(`/change-reservation/${_id}`);

  return (
    <div>
      <div className='row line-bottom'>
        <div className='col-xl-3 col-md-3 col-3'>
          <p className='less-padding'>
            <Link href={`reservation-confirmation/${_id}`}>
              <a>{orderNo}</a>
            </Link>
          </p>
        </div>
        <div className='col-2'>
          <p className='less-padding' style={{ overflowWrap: 'anywhere' }}>
            {moment(dateOfReservation).format('MM/DD/YYYY')}
          </p>
        </div>
        <div className='col-2 text-end'>
          <p className='less-padding' style={{ overflowWrap: 'anywhere' }}>
            ${totalPrice}
          </p>
        </div>
        <div className='col-5 text-end'>
          {/* <Link href={`/change-reservation/${_id}`}>
            <a className='btn btn-primary btn-outline-primary'>Change</a>
          </Link> */}
          <button
            className='btn btn-primary btn-outline-primary'
            onClick={handleChangeReserv}
            disabled={!change}
          >
            Change
          </button>

          <button
            className='btn btn-primary btn-outline-primary ml-2'
            onClick={handleShow}
            disabled={!cancel}
          >
            Cancel
          </button>

          <Link href={`reservation-confirmation/${_id}`}>
            <a className='btn btn-primary btn-outline-primary ml-2'>View</a>
          </Link>
        </div>
      </div>
      <Modal show={show}>
      { !refundLoading &&
        <div>
        <Modal.Body className='text-start'>
          <h2>Are you sure you want to cancel your reservation?</h2>
          {myDate - currDate <= 259200000 ? (
            <p>
              There will be no refund because the reservation is within 3 days.
              Are you sure you want to cancel your reservation?
            </p>
          ) : (
            <p>
              The cancellation fee $5.00 will be deducted from your refund. Are
              you sure you want to cancel your reservation?
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className='btn btn-primary btn-outline-primary'
            onClick={handleClose}
          >
            No
          </button>
          <button className='btn btn-primary' onClick={handleCancelReservation}>
            Yes
          </button>
        </Modal.Footer>
        </div>
        }
        { refundLoading &&
          <div>
            <PaymentLoader /> 
            <button
              id="cancelmodel-closeAlt"
              data-dismiss="modal"
              className="d-none"
            >
              Go back
            </button>
          </div>
        }
      </Modal>
    </div>
  );
}
