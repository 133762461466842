import { useContext, useEffect, useState } from 'react';
import API from 'Services/api';
import { SubHeader } from '..';
import { Loader, PaymentLoader } from 'Atoms/';
import moment from 'moment';
import Payment from './Payment';
import { Modal } from 'react-bootstrap';
import { useRouter } from 'next/router';
import Link from 'next/link';
import toast, { Toaster } from 'react-hot-toast';
import Timer from '../../../Timer';
import { TimerContext } from 'Lib/contexts/TimerContext';
import PaymentProfiles from './PaymentProfiles';

const Index = () => {
  const { stopTimer } = useContext(TimerContext);
  const [currData, setCurrData] = useState(null);
  const [newData, setNewData] = useState(null);
  const [orderNo, setOrderNo] = useState(null);
  const [updateReservation, setUpdateReservation] = useState(null);
  const [show, setShow] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [grandTotal, setGrandTotal] = useState('');
  const [customerData, setCustomerData] = useState('');
  const router = useRouter();
  const [orderId, setOrderId] = useState('');
  const [terms, setTerms] = useState(false);
  const [flag, setFlag] = useState(false);
  const [paymentFail, setPaymentFail] = useState(null);
  const [PaymentsGroup, setPaymentsGroup] = useState([]);
  const [PaymentSelected, setPaymentSelected] = useState('-1');
  useEffect(() => {
    const apiHit = async () => {
      // let reqID = localStorage.getItem('reqID');
      // if (reqID) {
      //   updateData.reqID = reqID;
      // }
      // const { data } = await API.post(
      //   '/reservations/checkReservationUpdate',
      //   updateData
      // );
      // console.log(data);
      // setNewData(data?.response);
      // console.log('reqID in reponse:' + data?.response.reqID);
      // localStorage.setItem('reqID', data?.response.reqID);
      // setGrandTotal(data?.response?.amountToCharge);
      try {
        //const { data } = await API.get('puc/paymentprofiles');
        //setPaymentsGroup(data.data.paymentProfiles);
      } catch (err) {
        console.log(err);
      }
    };
    apiHit();
    if (localStorage.getItem('updateReservation')) {
      const updateData = JSON.parse(localStorage.getItem('updateReservation'));
      const newDataDetails = JSON.parse(
        localStorage.getItem('new_updated_details')
      );

      // apiHit(updateData);
      setCurrData(JSON.parse(localStorage.getItem('currReservation')));
      setNewData(newDataDetails);
      setOrderNo(localStorage.getItem('orderNo'));
      setUpdateReservation(updateData);
      setCustomerData(JSON.parse(localStorage.getItem('billingInfo')));
      setOrderId(localStorage.getItem('OrderId'));
      setGrandTotal(newDataDetails.amountToCharge);
    }
  }, []);
  if (!currData || !newData) return <Loader />;
  
  const handlePay = async () => {
    handleShow();
    setPaymentLoading(true);
    
    const { _id, ...newCustomer } = customerData;
    
    const obj = {
      ...updateReservation,
      date: moment(updateReservation.date).format('YYYY-MM-DD'),
      customerData: newCustomer,
      type: 'puc',
      origin: 'puc'
    };
    
    try {
      let reqID = newData.reqID;
      if (reqID) {
        obj.reqID = reqID;
      }
      
      console.log(obj)
      const { data } = await API.post('/reservations/update/visitor', obj);
      // console.log('Hello', { ...data });
      if (data.message === 'Success') {
        localStorage.removeItem('billingInfo');
        localStorage.removeItem('orderNo');
        localStorage.removeItem('updateReservation');
        localStorage.setItem('paymentData', JSON.stringify(data.data));
        localStorage.setItem('oldAmount', newData.oldAmount);
        //stopTimer();
        router.push(data.data.checkoutUrl);
        //handleClose();
      }
    } catch (err) {
      setPaymentFail(err.response.data.error);
    } 
  }

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleUpdateReservation = async (
    dataValue = '-',
    dataDescriptor = '-',
    saveCustomerProfile
  ) => {
    const { _id, ...newCustomer } = customerData;
    const obj = {
      ...updateReservation,
      date: moment(updateReservation.date).format('YYYY-MM-DD'),
      dataValue,
      dataDescriptor,
      customerData: newCustomer,
      saveCustomerProfile,
    };
    try {
      let reqID = newData.reqID;
      if (reqID) {
        obj.reqID = reqID;
      }
      const { data } = await API.post('/reservations/update/visitor', obj);
      console.log('Hello', { ...data });
      if (data.message === 'Success') {
        localStorage.removeItem('billingInfo');
        localStorage.removeItem('orderNo');
        localStorage.removeItem('updateReservation');
        localStorage.setItem('paymentData', JSON.stringify(data.data));
        localStorage.setItem('oldAmount', newData.oldAmount);
        stopTimer();
        router.push('/confirmation-change');
        handleClose();
      }
    } catch (err) {
      console.log('Err', { ...err });
      if (err?.response?.status === 400 && err?.response?.data?.error) {
        setPaymentFail(err.response.data.error);
        //toast.error(err.response.data.error);
        setFlag(false);
      }
    }
  };

  const ErrorModal = () => (
    <div>
      {paymentFail}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '2em',
        }}
      >
        <button
          type='button'
          className='btn btn-primary'
          onClick={handleClose}
        >
          OK
        </button>
      </div>
    </div>
  );

  // const handlePaymentSelect = (e) => setPaymentSelected(e.target.value);

  const handlePaymentCard = async () => {
    handleShow();
    setPaymentLoading(true);

    const { _id, ...newCustomer } = customerData;
    const obj = {
      ...updateReservation,
      date: moment(updateReservation.date).format('YYYY-MM-DD'),
      dataValue: '-',
      dataDescriptor: '-',
      customerData: newCustomer,
      paymentProfileId: PaymentSelected,
    };
    try {
      let reqID = newData.reqID;
      if (reqID) {
        obj.reqID = reqID;
      }
      const { data } = await API.post('/reservations/update', obj);
      // console.log('Hello', { ...data });
      if (data.message === 'Success') {
        localStorage.removeItem('billingInfo');
        localStorage.removeItem('orderNo');
        localStorage.removeItem('updateReservation');
        localStorage.setItem('paymentData', JSON.stringify(data.data));
        localStorage.setItem('oldAmount', newData.oldAmount);
        stopTimer();
        router.push('/confirmation-change');
        handleClose();
      }
    } catch (err) {
      // console.log('Err', { ...err });
      if (err?.response?.status === 400 && err?.response?.data?.error) {
        setPaymentFail(err.response.data.error);
        //toast.error(err.response.data.error);
        setFlag(false);
      }
    }
  };

  return (
    <>
      {flag ? (
        <Loader />
      ) : (
        <div className='container'>
          <div>
            <Toaster />
          </div>
          <SubHeader currPage='allReservation' />

          <div className='row row-padding-top line-bottom-dark'>
            <div className='col-md-8 col-sm-8 col-xs-12'>
              <h2>Confirm Changes</h2>
            </div>
            <Timer />
          </div>

          <div className='row'>
            <div className='col-12'>
              <h4>Order #{orderNo}</h4>
            </div>
          </div>

          <div className='row line-bottom'>
            <div className='col-xl-3 col-md-3 col-3'>
              <p className='less-padding'>
                <strong>Ticket</strong>
              </p>
            </div>
            <div className='col-xl-1 col-md-2 col-1'>
              <p className='less-padding'>
                <strong>Qty</strong>
              </p>
            </div>
            <div className='col-xl-2 col-md-2 col-3'>
              <p className='less-padding'>
                <strong>Date</strong>
              </p>
            </div>
            <div className='col-xl-2 col-md-3 col-3'>
              <p className='less-padding'>
                <strong>Time</strong>
              </p>
            </div>
            <div className='col-1 text-end'>
              <p className='less-padding'>
                <strong>Price</strong>
              </p>
            </div>
          </div>

          <div className='row line-bottom-dark highlight'>
            <div className='col-xl-3 col-md-3 col-3'>
              <p className='less-padding'>Original Reservation Details</p>
            </div>
          </div>
          {currData.map((item) => {
            return (
              <div className='row line-bottom'>
                <div className='col-xl-3 col-md-3 col-3'>
                  <p className='less-padding'>{item.ticket}</p>
                </div>
                <div className='col-xl-1 col-md-2 col-1'>
                  <p className='less-padding'>{item.qty}</p>
                </div>
                <div className='col-xl-2 col-md-2 col-3'>
                  <p className='less-padding'>{item.date}</p>
                </div>
                <div className='col-xl-2 col-md-3 col-3'>
                  <p className='less-padding'>{item.time}</p>
                </div>
                <div className='col-1 text-end'>
                  <p className='less-padding'>${item.price}</p>
                </div>
              </div>
            );
          })}

          <div className='row line-bottom-dark'>
            <div className='col-xl-3 col-md-3 col-3'></div>
            <div className='col-md-1 col-1'></div>
            <div className='col-xl-2 col-md-3 col-2'></div>
            <div className='col-xl-2 col-md-2 col-4'>
              <p className='less-padding text-end'>Original charge</p>
            </div>
            <div className='col-1 text-end'>
              <p className='less-padding'>${newData.oldAmount}</p>
            </div>
          </div>

          {/* <div className='row line-bottom highlight'>
        <div className='col-xl-3 col-md-3 col-3'>
          <p className='less-padding'>New entry</p>
        </div>
        <div className='col-xl-1 col-md-2 col-1'>
          <p className='less-padding'>{updateReservation.quantity}</p>
        </div>
        <div className='col-xl-2 col-md-2 col-3'>
          <p className='less-padding'>
            {moment(updateReservation.date).format('M/DD/YYYY')}
          </p>
        </div>
        <div className='col-xl-2 col-md-3 col-3'>
          <p className='less-padding'>{updateReservation.timeslots[0].label}</p>
        </div>
        <div className='col-1 text-end'>
          <p className='less-padding'>${newData.newAmount}</p>
        </div>
        <div className='col-2 text-end'>
          <p className='less-padding'>
            <em>Additional charge</em>
          </p>
        </div>
      </div> */}

          {/* <div className='row line-bottom highlight'>
        <div className='col-xl-3 col-md-3 col-3'>
          <p className='less-padding'>Change fee</p>
        </div>
        <div className='col-xl-1 col-md-2 col-1'>
          <p className='less-padding'>1</p>
        </div>
        <div className='col-xl-2 col-md-2 col-3'>
          <p className='less-padding'></p>
        </div>
        <div className='col-xl-2 col-md-3 col-3'>
          <p className='less-padding'></p>
        </div>
        <div className='col-1 text-end'>
          <p className='less-padding'>Waived</p>
        </div>
      </div>

      <div className='row line-bottom-dark'>
        <div className='col-xl-3 col-md-3 col-3'></div>
        <div className='col-md-1 col-1'></div>
        <div className='col-xl-1 col-md-2 col-3'></div>
        <div className='col-xl-3 col-md-3 col-3'>
          <p className='less-padding text-end'>Additional charge total</p>
        </div>
        <div className='col-1 text-end'>
          <p className='less-padding'>${newData.amountToCharge}</p>
        </div>
      </div> */}

          <div className='row line-bottom-dark highlight'>
            <div className='col-xl-3 col-md-3 col-3'>
              <p className='less-padding'>Updated Reservation Details</p>
            </div>
          </div>
          {newData.updatedReservationDetails.map((item) => {
            if (item.ticket === 'New charge') {
              return (
                <div className='row line-bottom'>
                  <div className='col-xl-3 col-md-3 col-3'></div>
                  <div className='col-md-1 col-1'></div>
                  <div className='col-xl-2 col-md-3 col-2'></div>
                  <div className='col-xl-2 col-md-2 col-4'>
                    <p className='less-padding text-end'>{item.ticket}</p>
                  </div>
                  <div className='col-1 text-end'>
                    <p className='less-padding'>${item.price}</p>
                  </div>
                </div>
              );
            }
            return (
              <div className='row line-bottom'>
                <div className='col-xl-3 col-md-3 col-3'>
                  <p className='less-padding'>{item.ticket}</p>
                </div>
                <div className='col-xl-1 col-md-2 col-1'>
                  <p className='less-padding'>{item.qty}</p>
                </div>
                <div className='col-xl-2 col-md-2 col-3'>
                  <p className='less-padding'>{item.date}</p>
                </div>
                <div className='col-xl-2 col-md-3 col-3'>
                  <p className='less-padding'>{item.time}</p>
                </div>
                <div className='col-1 text-end'>
                  <p className='less-padding'>
                    {item.price !== 'Waived' ? `$${item.price}` : item.price}
                  </p>
                </div>
              </div>
            );
          })}
          
          {/*
          {newData.takePayment && (
            <div className='row'>
              <div className='col-xl-6 col-md-6'>
                <label for='mobile'>
                  <h4>Payment*</h4>
                </label>

                {PaymentsGroup?.map((payment, idx) => (
                  // <div className='form-check' key={idx}>
                  //   <input
                  //     className='form-check-input'
                  //     type='radio'
                  //     name='PaymentSelected'
                  //     value={payment.customerPaymentProfileId}
                  //     checked={
                  //       PaymentSelected === payment.customerPaymentProfileId
                  //     }
                  //     onChange={handlePaymentSelect}
                  //   />
                  //   <label className='form-check-label'>
                  //     {payment.payment.creditCard.icon && (
                  //       <img
                  //         src={payment.payment.creditCard.icon}
                  //         width='50'
                  //         style={{ marginRight: '8px' }}
                  //       />
                  //     )}
                  //     {payment.payment.creditCard.cardNumber}
                  //   </label>
                  // </div>
                  <PaymentProfiles payment={payment} setPaymentsGroup={setPaymentsGroup} PaymentSelected={PaymentSelected} setPaymentSelected={setPaymentSelected} key={idx} />
                ))}

                <div className='form-check'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='PaymentSelected'
                    value='-1'
                    checked={PaymentSelected === '-1'}
                    onChange={(e)=>setPaymentSelected(e.target.value)}
                  />
                  <label className='form-check-label'>Add a new card</label>
                </div>
              </div>
            </div>
          )}
          */}

          <div className='row'>
            <div className='col-xl-12 col-12'>
              <p>
                <span className='red'>
                  Partial refunds will be offered if cancelled more than three
                  (3) days in advance.
                </span>{' '}
                Modifications will be for parking and/or entry reservation
                times, space available basis and done at least 7 days in advance
                (additional fees may apply, partial refunds may or may not
                apply).
              </p>
              <p>
                <span className='red'>We are not responsible</span> for
                last-minute closures of the park due to weather, safety
                concerns, road closures, or trail closures from any cause
                whatsoever. Full or partial refunds may be considered but will
                be at the sole discretion of the Hawaii State Parks Division.
              </p>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  value={terms}
                  onChange={() => setTerms((term) => !term)}
                  id='flexCheckDefault'
                />
                <label className='form-check-label' htmlFor='flexCheckDefault'>
                  Please check here that you agree with all terms listed above.
                </label>
              </div>
            </div>
          </div>

          <div className='row row-padding-bottom'>
            <div className='col-md-12 text-end' style={{ paddingTop: '15px' }}>
              <Link href={`/change-reservation/${orderId}`}>
                <a className='btn btn-primary btn-outline-primary'>Go back</a>
              </Link>
              {newData.takePayment ? (
                <button
                  className='btn btn-primary ml-2'
                  onClick={handlePay}
                  disabled={!terms}
                  style={{ background: '#447969' }}
                >
                  Confirm & Pay
                </button>
              ) : (
                <button
                  className='btn btn-primary ml-2'
                  onClick={() => {
                    setFlag(true);
                    handleUpdateReservation();
                  }}
                  disabled={!terms}
                  style={{ background: '#447969' }}
                >
                  Confirm
                </button>
              )}

              <Modal show={show} onHide={handleClose}>
                <Modal.Body>
                  {paymentLoading ? (
                    !paymentFail ? (
                      <PaymentLoader />
                    ) : (
                      <ErrorModal />
                    )
                  ) : (
                    <Payment
                      setPaymentLoading={setPaymentLoading}
                      handleClose={handleClose}
                      handleUpdateReservation={handleUpdateReservation}
                      grandTotal={grandTotal}
                      setPaymentFail={setPaymentFail}
                    />
                  )}
                </Modal.Body>
              </Modal>
              {/* </div> */}
              {/* </div> */}
              {/* </div> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Index;
