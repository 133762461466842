import React from 'react';
import Link from 'next/link';
import { useContext, useEffect } from 'react';
import { UserContext } from 'Lib/contexts/OrderContext';
import Cookies from 'js-cookie';

const Index = () => {
  const userLogin = useContext(UserContext);
  
  useEffect(() => {
    if (Cookies.get('token')) {
      userLogin.setIsLogin(true);
    }
  }, []);
  
  return (
    <div className='sub-footer'>
      <div className='container' style={{ display: 'flex' }}>
        <div className='col-lg-9 col-md-8'>
          {userLogin.isLogin ? (
            <p style={{ padding: '20px 0 20px' }}>
              <Link href='/reservations'>
                <a>
                  <strong>{JSON.parse(localStorage.getItem('parkData')).parkName}:</strong>
                </a>
              </Link>
              <span className='Break'></span>&nbsp;
              <Link href={"/" + JSON.parse(localStorage.getItem('parkData')).parkUrl + "/faq"}>
                <a>FAQ</a>
              </Link>{' '}
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <Link href={"/" + JSON.parse(localStorage.getItem('parkData')).parkUrl + "/about"}>
                <a>About the Reservations</a>
              </Link>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <Link href={"/" + JSON.parse(localStorage.getItem('parkData')).parkUrl + "/privacy-policy"}>
                <a>Privacy Policy</a>
              </Link>
            </p>
          ) : (
            <p style={{ padding: '20px 0 20px' }}>
              <Link href='/reservations'>
                <a>
                  <strong>Diamond Head Monument State Park:</strong>
                </a>
              </Link>
              <span className='Break'></span>&nbsp;
              <Link href='/diamondhead/faq'>
                <a>FAQ</a>
              </Link>{' '}
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <Link href='/diamondhead/about'>
                <a>About the Reservations</a>
              </Link>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <Link href='/diamondhead/privacy-policy'>
                <a>Privacy Policy</a>
              </Link>
            </p>
          )} 
        </div>
      </div>
    </div>
  );
};

export default Index;
