import Cookies from 'js-cookie';
import { UserContext } from 'Lib/contexts/OrderContext';
import { TimerContext } from 'Lib/contexts/TimerContext';
import Link from 'next/link';
import router from 'next/router';
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import API from 'Services/api';
import { errorObj } from 'Lib/constants';

const Index = ({ flag }) => {
  const userLogin = useContext(UserContext);
  const { stopTimer } = useContext(TimerContext);
  const [parkId, setParkId] = useState("");

  useEffect(() => {
    if (Cookies.get('token')) {
      userLogin.setIsLogin(true);
      
      if(!localStorage.getItem('parkData')) {
        localStorage.setItem('parkData', JSON.stringify({
          parkUrl: 'diamondhead',
          parkName: 'Diamond Head State Monument'
        }))
      }
    }
    
    if(!localStorage.getItem('parkData')) {
      setParkId('general')
    }
    else {
      setParkId(JSON.parse(localStorage.getItem('parkData'))._id)
    }
  }, []);

  // Logout Functionality
  const handleClick = async () => {
    try {
      await API.delete('/puc/deleteCart');
    } catch (err) {
      console.log(err);
    }
    try {
      const { data } = await API.get('/puc/logout');
      if (data?.message === 'Success') {
        const notices = localStorage.getItem('notices')
        
        stopTimer();
        localStorage.clear();
        localStorage.setItem('timer', 900);
        localStorage.setItem('notices', notices);
        Cookies.remove('token');
        Cookies.remove('userId');
        toast.success('Logged Out');
        router.push('/');
      }
    } catch (error) {
      toast(error.response.data.error, errorObj);
    }
  };

  return (
    <>
      <div className={userLogin.isLogin && flag ? 'header-int ' + JSON.parse(localStorage.getItem('parkData')).parkUrl + 'Banner' : 'header-int'}>
        <header className='header-repeater'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-7 col-lg-7 col-12'>
                <Link href='/'>
                  <a className='navbar-brand'>
                    <img
                      src='/images/go-hawaii-state-parks.png'
                      alt="Go Hawai'i state Parks logo"
                      width='414'
                      className='img-fluid'
                    />
                  </a>
                </Link>
              </div>
              <div className='col-xl-5 col-lg-5 col-12 link-top text-end'>
                {!userLogin.isLogin ? (
                  <Link href='/'>
                    <a>LOGIN</a>
                  </Link>
                ) : (
                  <Link href='/'>
                    <a onClick={handleClick}>LOGOUT</a>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </header>
        {userLogin.isLogin && flag && (
          <div className='container'>
            <h1 className='display-1 small-header'>
              {userLogin.isLogin ? (
                <span>{JSON.parse(localStorage.getItem('parkData')).parkName}</span>
              ) : (
                <span>Diamond Head State Monument</span>
              )}
              <br />
              PUC Reservations
            </h1>
          </div>
        )}
      </div>
      <div className='blue'></div>
    </>
  );
};

export default Index;
