import React from 'react';

function TextError(props) {
  return (
    <div style={{ color: '#ED5F5F', marginLeft: '0.5em' }}>
      {props.children}
    </div>
  );
}

export default TextError;
