import { useEffect, useState } from 'react';
import { SubHeader } from '..';
import { Loader } from 'Atoms/';

const Index = () => {
  const [cancelData, setCancelData] = useState(null);

  useEffect(() => {
    setCancelData(JSON.parse(localStorage.getItem('cancelReservation')));
  }, []);

  if (!cancelData) return <Loader />;
  console.log(cancelData);

  const { customerData, feeResponse, reservationData } = cancelData;

  return (
    <>
      <div className='container'>
        <SubHeader currPage='searchReservation' />
        <div className='row row-padding-top'>
          <div className='col-lg-12 col-md-12 col-12'>
            <h2>Order #{reservationData.orderNo} cancelled</h2>
          </div>
        </div>

        <div className='row line-bottom'>
          <div className='col-xl-4 col-md-4 col-2'>
            <p className='less-padding'>
              <strong>Ticket</strong>
            </p>
          </div>
          <div className='col-xl-1 col-md-2 col-2'>
            <p className='less-padding'>
              <strong>Qty</strong>
            </p>
          </div>
          <div className='col-xl-2 col-md-2 col-3'>
            <p className='less-padding'>
              <strong>Date</strong>
            </p>
          </div>
          <div className='col-xl-2 col-md-2 col-3'>
            <p className='less-padding'>
              <strong>Time</strong>
            </p>
          </div>
          <div className='col-2 text-end'>
            <p className='less-padding'>
              <strong>Price</strong>
            </p>
          </div>
        </div>

        {feeResponse.updatedReservationDetails.map((item, idx) => (
          <div className='row line-bottom' key={idx}>
            <div className='col-xl-4 col-md-4 col-2'>
              <p className='less-padding'>{item.ticket}</p>
            </div>
            <div className='col-xl-1 col-md-2 col-2'>
              <p className='less-padding'>{item.qty}</p>
            </div>
            <div className='col-xl-2 col-md-2 col-3'>
              <p className='less-padding'>{item.date}</p>
            </div>
            <div className='col-xl-2 col-md-2 col-3'>
              <p className='less-padding'>{item.time}</p>
            </div>
            <div className='col-2 text-end'>
              <p className='less-padding'>${item.price}</p>
            </div>
          </div>
        ))}

        <div className='row  line-bottom-dark'>
          <div className='col-xl-4 col-md-4 col-2'></div>
          <div className='col-md-1 col-1'></div>
          <div className='col-xl-1 col-md-1 col-3'></div>
          <div className='col-xl-3 col-md-4 col-4'>
            <p className='less-padding text-end'>Original total</p>
          </div>
          <div className='col-2 text-end'>
            <p className='less-padding'>${reservationData.totalPrice}</p>
          </div>
        </div>

        {/* <div className='row line-bottom highlight'>
          <div className='col-xl-4 col-md-4 col-2'>
            <p className='less-padding'>
              <strong>Cancellation fee</strong>
            </p>
          </div>
          <div className='col-xl-1 col-md-2 col-2'></div>
          <div className='col-xl-2 col-md-2 col-3'></div>
          <div className='col-xl-2 col-md-2 col-3'></div>
          <div className='col-2 text-end'>
            <p className='less-padding'>${feeResponse.amount}</p>
          </div>
        </div>

        <div className='row  line-bottom-dark'>
          <div className='col-xl-4 col-md-4 col-2'></div>
          <div className='col-md-1 col-1'></div>
          <div className='col-xl-1 col-md-1 col-3'></div>
          <div className='col-xl-3 col-md-4 col-4'>
            <p className='less-padding text-end'>
              Total cancellation fee
              <br />
              <strong>Refund amount</strong>
            </p>
          </div>
          <div className='col-2 text-end'>
            <p className='less-padding'>
              ${feeResponse.amount}
              <br />
              <strong>
                $
                {feeResponse.refund
                  ? reservationData.totalPrice - feeResponse.amount
                  : 0}
              </strong>
            </p>
          </div>
        </div> */}

        <div className='row line-bottom'>
          <div className='col-xl-8 col-10'>
            <div className='row'>
              <div className='col-xl-6 col-12'>
                <p className='less-padding' style={{ paddingTop: '.2rem' }}>
                  <strong>Customer details:</strong>
                </p>
              </div>
            </div>

            <div className='row'>
              <div className='col-xl-3 col-5'>
                <p className='less-padding'>Company:</p>
              </div>
              <div className='col-xl-5 col-7'>
                <p className='less-padding'>{customerData.company}</p>
              </div>
            </div>

            <div className='row'>
              <div className='col-xl-3 col-5'>
                <p className='less-padding'>Name:</p>
              </div>
              <div className='col-xl-5 col-7'>
                <p className='less-padding'>
                  {`${customerData.firstName} ${customerData.lastName}`}
                </p>
              </div>
            </div>

            <div className='row'>
              <div className='col-xl-3 col-5'>
                <p className='less-padding'>Email:</p>
              </div>
              <div className='col-xl-5 col-7'>
                <p className='less-padding'>{customerData.email}</p>
              </div>
            </div>

            <div className='row'>
              <div className='col-xl-3 col-5'>
                <p className='less-padding'>Telephone:</p>
              </div>
              <div className='col-xl-5 col-7'>
                <p className='less-padding'>{customerData.mobile}</p>
              </div>
            </div>

            <div className='row'>
              <div className='col-xl-3 col-5'>
                <p className='less-padding'>PUC License #:</p>
              </div>
              <div className='col-xl-5 col-7'>
                <p className='less-padding'>{customerData.pucLicense}</p>
              </div>
            </div>

            <div className='row'>
              <div className='col-xl-3 col-5'>
                <p className='less-padding'>Address:</p>
              </div>
              <div className='col-xl-5 col-7'>
                <p className='less-padding'>
                  {customerData.address}
                  <br />
                  {`${customerData.city} ${customerData.stateProvince} ${customerData.zip}`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Index;
