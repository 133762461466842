import moment from 'moment';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import API from 'Services/api';

const Entries = ({ dataItem }) => {
  const [show, setShow] = useState(false);
  const { orderNo, date, amount, reservationId, buttonFlags } = dataItem;
  const { cancel, change } = buttonFlags;

  const router = useRouter();

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleCancelReservation = async () => {
    try {
      const { data } = await API.post('/reservations/cancel', {
        reservationId,
      });

      localStorage.setItem('cancelReservation', JSON.stringify(data.data));
      router.push('/cancel-confirmation');
    } catch (err) {
      console.log(err);
    }
  };

  const currDate = new Date();
  const myDate = new Date(date);
  const handleChangeReserv = () =>
    router.push(`/change-reservation/${reservationId}`);

  return (
    <div className='row line-bottom'>
      <div className='col-xl-3 col-md-3 col-3'>
        <p className='less-padding'>
          <Link href={`reservation-confirmation/${reservationId}`}>
            <a>{orderNo}</a>
          </Link>
        </p>
      </div>
      <div className='col-md-2 col-3'>
        <p className='less-padding'>{moment(date).format('MM/DD/YYYY')}</p>
      </div>
      <div className='col-2 text-end'>
        <p className='less-padding'>{amount}</p>
      </div>
      <div className='col-md-5 col-12 text-end'>
        {/* <Link href={`/change-reservation/${reservationId}`}>
          <a className='btn btn-primary btn-outline-primary'>Change</a>
        </Link> */}
        <button
          className='btn btn-primary btn-outline-primary'
          onClick={handleChangeReserv}
          disabled={!change}
        >
          Change
        </button>

        <button
          className='btn btn-primary btn-outline-primary ml-2'
          onClick={handleShow}
          disabled={!cancel}
        >
          Cancel
        </button>

        <Modal show={show} onHide={handleClose}>
          <Modal.Body className='text-start'>
            <h2>Are you sure you want to cancel your reservation?</h2>
            {myDate - currDate <= 12096e5 ? (
              <p>
                There will be no refund because the reservation is within 14
                days. Are you sure you want to cancel your reservation?
              </p>
            ) : (
              <p>
                The cancellation fee $5.00 will be deducted from your refund.
                Are you sure you want to cancel your reservation?
              </p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              type='button'
              className='btn btn-primary '
              onClick={handleClose}
            >
              No
            </button>
            <button
              type='button'
              handle
              className='btn btn-primary btn-outline-primary'
              onClick={handleCancelReservation}
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
        <Link href={`reservation-confirmation/${reservationId}`}>
          <a className='btn btn-primary btn-outline-primary ml-1'>View</a>
        </Link>
      </div>
    </div>
  );
};
export default Entries;
