import React from 'react';
import { Formik, Form } from 'formik';
import { PUCRegisterSchema } from 'Lib/validators';
import {
  CheckboxGroup,
  MySelect,
  MyTextInput,
  MyPhoneInput,
} from 'Atoms/Formik';
import { States, CheckboxOptions } from 'Lib/constants';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Loader } from 'Atoms/';
import { RegionDropdown } from 'react-country-region-selector';

export default function Index() {
  const router = useRouter();
  const [flag, setFlag] = useState(false);
  const [initialValues, setInitialValues] = useState({
    company: '',
    yourName: '',
    email: '',
    verifyEmail: '',
    telephone: '',
    pucLicense: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    islands: [],
  });

  useEffect(() => {
    if (localStorage.getItem('puc_register_data')) {
      const registerDataParsed = JSON.parse(
        localStorage.getItem('puc_register_data')
      );
      setInitialValues(registerDataParsed);
    }
    setFlag(true);
  }, []);

  if (!flag) {
    return <Loader />;
  }

  return (
    <div className='container'>
      <div className='row row-padding-top'>
        <div className='col-xl-8 col-md-8'>
          <h2>PUC Reservation System Registration</h2>
          <p>Please fill out the form below:</p>
        </div>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={PUCRegisterSchema}
        onSubmit={async (values) => {
          localStorage.removeItem('puc_register_data');
          localStorage.setItem('puc_register_data', JSON.stringify(values));
          router.push('/registration-review');
        }}
      >
        {(props) => {
          return (
            <Form>
              <div className='row'>
                <div className='col-xl-6 col-md-6'>
                  <label htmlFor='email'>Company*</label>
                  <MyTextInput
                    name='company'
                    type='text'
                    className='form-control'
                    id='company'
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-xl-6 col-md-6'>
                  <label htmlFor='orderNumber'>Your Name*</label>
                  <MyTextInput
                    name='yourName'
                    type='text'
                    className='form-control'
                    id='yourName'
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-xl-6 col-md-6'>
                  <label htmlFor='mobile'>Email*</label>
                  <MyTextInput
                    name='email'
                    type='email'
                    className='form-control'
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-xl-6 col-md-6'>
                  <label htmlFor='lasrName'>Verify Email*</label>
                  <MyTextInput
                    name='verifyEmail'
                    type='email'
                    className='form-control'
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-xl-6 col-md-6'>
                  <label htmlFor='lasrName'>Telephone*</label>
                  {/* <MyTextInput
                    name='telephone'
                    type='tel'
                    className='form-control'
                  /> */}
                  <MyPhoneInput
                    name='telephone'
                    className='form-control'
                    formikObj={props}
                    style={{
                      padding: '5px',
                    }}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-xl-6 col-md-6'>
                  <label htmlFor='lasrName'>PUC license #*</label>
                  <MyTextInput
                    name='pucLicense'
                    type='text'
                    className='form-control'
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-xl-6 col-md-6'>
                  <label htmlFor='orderNumber'>Address*</label>
                  <MyTextInput
                    name='address'
                    type='text'
                    className='form-control'
                    id='address'
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-xl-4 col-sm-2'>
                  <label htmlFor='city'>City*</label>
                  <MyTextInput
                    name='city'
                    type='text'
                    className='form-control'
                  />
                </div>
                <div className='col-xl-2 col-sm-2'>
                  <label htmlFor='state'>State*</label>
                  {/* <MySelect name='state' className='form-control'>
                    {States.map((item, index) => (
                      <option key={index} value={item.code}>
                        {item.name}
                      </option>
                    ))}
                  </MySelect> */}
                  <RegionDropdown
                    name='state'
                    className='form-control'
                    country='US'
                    value={props.values.state}
                    onChange={(_, e) => props.handleChange(e)}
                    countryValueType="short"
                    valueType="short"
                  />
                </div>
                <div className='col-xl-2 col-sm-2'>
                  <label htmlFor='zip'>Zip*</label>
                  <MyTextInput name='zip' type='tel' className='form-control' />
                </div>
              </div>
              <CheckboxGroup name='islands' options={CheckboxOptions} />

              <div className='row'>
                <div className='col-xl-12 col-sm-12'>
                  <p>
                    By registering for a P.U.C. account you agree with{' '}
                    <a
                      href='https://portal.ehawaii.gov/page/privacy-policy/'
                      target='_blank'
                    >
                      the State of Hawaii Privacy Policy and Terms of Use
                    </a>
                    . Please click on the links provided for further
                    information. Registering for a P.U.C. account does not give
                    you automatic access to the Hawai'i State Parks Reservation System. We
                    suggest you sign up for a P.U.C. account ahead of time to
                    make your reservation as smooth as possible. If you have any
                    problems with your P.U.C. number, or company name, etc.
                    please contact the Public Utilities Commission at (808)
                    586-2020 or puc@hawaii.gov.
                  </p>
                </div>
              </div>
              <div className='row row-padding-bottom'>
                <div className='col-12'>
                  <button type='submit' className='btn btn-primary mt-5'>
                    Register
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
