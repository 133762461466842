import React from 'react';
import { Spinner } from 'react-bootstrap';

const Index = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh',
      }}
    >
      <Spinner animation='border' />
    </div>
  );
};

export default Index;
