import React, { useState, useEffect, useContext } from 'react';
import API from 'Services/api';
import { Loader } from 'Atoms/';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { errorObj, TimeSlots } from 'Lib/constants';
import moment from 'moment';
import toast, { Toaster } from 'react-hot-toast';
import { useRouter } from 'next/router';
import { SubHeader } from '..';
import Cookies from 'js-cookie';
import { TimerContext } from 'Lib/contexts/TimerContext';

const Index = () => {
  const router = useRouter();
  const { clearTimer, getDeadTime } = useContext(TimerContext);

  const [parkRates, setParkRates] = useState([]);
  const [parkData, setParkData] = useState('');
  const [ticketId, setTicketId] = useState('-1');
  const [pucDate, setPucDate] = useState('');
  const [entry, setEntry] = useState('');
  const [timeSlots, setTimeSlots] = useState([]);
  const [showTimeSlots, setShowTimeSlots] = useState(TimeSlots);
  const [flag, setFlag] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);
  const [reservationId, setReservationId] = useState(null);
  
  const [dropOffId, setDropOffId] = useState('');
  const parkId = Cookies.get('parkId');

  useEffect(() => {
    async function apiHit() {
      const { data } = await API.get('/puc/park-rates', {
        params: {
          segmentKey: 3,
          parkId: parkId,
        },
      });

      setParkRates(data?.data?.data);
      setParkData(data?.data?.parkData);
      !localStorage.getItem('updateReservationData') &&
        setTicketId(data?.data?.data[0]);
        
      data?.data?.data.forEach((item) => {
        if(item.ticketName === 'Drop-off') {
          setDropOffId(item._id)
        }
      });
    }

    apiHit();

    if (localStorage.getItem('updateReservationData')) {
      const updateRes = JSON.parse(
        localStorage.getItem('updateReservationData')
      );
      // console.log(moment(updateRes.dateOfReservation).toDate());
      setIsUpdate(true);
      setTicketId(updateRes.ticketId);
      setPucDate(moment(updateRes.dateOfReservation).toDate());
      setEntry(updateRes.quantity);
      setTimeSlots(updateRes.timeslots);
      setReservationId(updateRes._id);
    }
  }, []);
  useEffect(() => {
    async function getTimeSlots() {
      const { data } = await API.get(
        '/parks/' + parkId + '/timeslots',
        {
          params: {
            segment: 'puc',
            ticketId: ticketId._id,
            quantity: entry,
            date: moment(pucDate).format('YYYY-MM-DD'),
          },
        }
      );

      if (data?.message === 'Success') {
        const newTimeSlots = data?.data?.timeslots;
        setShowTimeSlots(newTimeSlots);
        setFlag(false);
        newTimeSlots.forEach((item) => {
          if (isSlotPresent(item.slotId) && item.capacity <= 0) {
            console.log('SLots->', timeSlots);
            setTimeSlots((prev) =>
              prev.filter((slot) => slot.id != item.slotId)
            );
          }
        });
      }
    }
    if (
      (entry !== '' && pucDate !== '' && pucDate) ||
      localStorage.getItem('updateReservationData')
    )
      getTimeSlots();
    else {
      setFlag(true);
      setTimeSlots([]);
    }
  }, [pucDate, entry, ticketId]);

  if (!parkRates || !parkData) {
    return <Loader />;
  }
  const { aboutPark, hours } = parkData;

  function isSlotPresent(slotId) {
    for (let i = 0; i < timeSlots.length; i++) {
      if (timeSlots[i].id == slotId) {
        return true;
      }
    }
    return false;
  }

  const handleAddItem = async (e) => {
    e.preventDefault();

    const ApiUrl = isUpdate ? '/puc/updateCart' : '/puc/addItem';

    if (ticketId._id === dropOffId) {
      try {
        const { data } = await API.post(ApiUrl, {
          parkId: parkId,
          dateOfReservation: moment(pucDate).format('YYYY-MM-DD'),
          ticketId: ticketId._id,
          segment: 'puc',
          reservationId,
        });

        if (!isUpdate) clearTimer(getDeadTime());
        toast.success('Added Item Successfully');
        router.push('/cart');
      } catch (err) {
        console.log(err);
      }
      return;
    }

    if (
      entry === '' ||
      timeSlots.length === 0 ||
      pucDate === null ||
      pucDate === ''
    ) {
      toast('All Fields are required', errorObj);
      return;
    }
    if (entry > ticketId.maxLimit) {
      toast(`Maximum ${ticketId.maxLimit} entry tickets are allowed`, errorObj);
      return;
    }

    if (timeSlots.length > 2) {
      toast('Maximum 2 consecutive Time Slots are allowed', errorObj);
      return;
    }
    if (
      timeSlots.length === 2 &&
      Math.abs(timeSlots[0].id - timeSlots[1].id) > 1
    ) {
      toast('Maximum 2 consecutive Time Slots are allowed', errorObj);
      return;
    }

    try {
      const { data } = await API.post(ApiUrl, {
        parkId: parkId,
        dateOfReservation: moment(pucDate).format('YYYY-MM-DD'),
        ticketId: ticketId._id,
        quantity: entry,
        timeslots: timeSlots,
        reservationId,
      });
      console.log(data);
      console.log('FFF', isUpdate);
      if (!isUpdate) clearTimer(getDeadTime());
      toast.success('Added Item Successfully');
      router.push('/cart');
    } catch (err) {
      console.log(err);
    }
  };

  const handleSetEntry = (e) => {
    if (e.target.value < 1) {
      toast('Minimum entry should be 1.', errorObj);
    } else setEntry(e.target.value);
  };

  // Reset Form
  const handleReset = () => {
    setTimeSlots('');
    setPucDate('');
    setEntry('');
  };

  const handleAddTimeSlots = (item) => {
    setTimeSlots((prev) => {
      const selectTime = { id: item.slotId, label: item.label };

      const newTime = prev.filter((el) => el.id != item.slotId);

      if (isSlotPresent(item.slotId)) {
        return newTime;
      }
      return [...prev, selectTime];
    });
  };

  return (
    <div className='container'>
      <div>
        <Toaster />
      </div>

      <SubHeader currPage='makeReservation' />

      <div className='row row-padding-bottom'>
        <div className='col-lg-8 col-md-12 col-12'>
          <div className='row'>
            <div className='col-12'>
              <h2>Make a reservation</h2>
            </div>
          </div>

          <form onSubmit={handleAddItem}>
            <div className='row'>
              <div className='col-md-8 col-sm-8 col-xs-12'>
                <label>Ticket type</label>
                <select
                  className='form-control'
                  id='exampleFormControlSelect2'
                  placeholder='Choose one...'
                  onChange={(e) => {
                    setTicketId(JSON.parse(e.target.value));
                    setTimeSlots([]);
                  }}
                >
                  {parkRates?.map((item) => (
                    <option
                      key={item._id}
                      value={JSON.stringify(item)}
                      selected={item._id === ticketId._id}
                    >
                      {item.ticketName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <label>Date</label>

                <div className='input-group input-daterange'>
                  <DatePicker
                    id='datefield'
                    minDate={new Date(Date.now())}
                    maxDate={new Date(Date.now() + 3600 * 1000 * 24 * parseInt(process.env.NEXT_PUBLIC_RESERVATION_WINDOW?process.env.NEXT_PUBLIC_RESERVATION_WINDOW:'14'))}
                    selected={pucDate}
                    onChange={(date) => setPucDate(date)}
                    placeholderText='Booking Date'
                  />
                </div>
              </div>
              {ticketId._id !== dropOffId && (
                <div className='col-md-2 col-sm-2 col-xs-12'>
                  <label htmlFor='people'>Entry</label>
                  <input
                    type='number'
                    min='1'
                    oninput="validity.valid||(value='');"
                    name='Entry'
                    value={entry}
                    onChange={(e) => setEntry(e.target.value)}
                    width='100%'
                    placeholder='Entry'
                  />
                </div>
              )}
            </div>
            {ticketId._id !== dropOffId && (
              <div className='row'>
                <div className='col-12'>
                  <label>Time</label>
                  <div className='input-group mb-3'>
                    {showTimeSlots.map((item) => (
                      <a
                        key={item.slotId}
                        className={`btn btn-primary time ${
                          (flag || item.capacity <= 0) && 'booked'
                        }`}
                        style={{
                          backgroundColor:
                            isSlotPresent(item.slotId) && '#396a5b',
                          color: isSlotPresent(item.slotId) && '#fff',
                          pointerEvents: (flag || item.capacity <= 0) && 'none',
                        }}
                        onClick={() => handleAddTimeSlots(item)}
                      >
                        {item.label}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            )}
            <div className='row row-padding-top'>
              <div className='col-md-6 col-sm-6 col-xs-12'>
                <button
                  type='reset'
                  className='btn btn-primary btn-outline-primary'
                  onClick={handleReset}
                >
                  Clear
                </button>

                <button type='submit' className='btn btn-primary ml-1'>
                  Add to cart
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className='col-lg-4 col-md-4 col-12'>
          <div className='row line-bottom'>
            <div className='col-12'>
              <h4 style={{ margin: '2.5rem 0 0.5rem' }}>Park rates:</h4>
            </div>
          </div>
          {parkRates?.map((item, index) => (
            <div className='row line-bottom'>
              <div className='col-md-8 col-sm-8 col-xs-12'>
                {item.ticketName}
              </div>
              <div className='col-md-4 col-sm-4 col-xs-12 text-end'>
                ${item.price}
              </div>
            </div>
          ))}
          <div className='row line-bottom'>
            <div className='col-md-8 col-sm-8 col-xs-12'>Entry</div>
            <div className='col-md-4 col-sm-4 col-xs-12 text-end'>$5.00</div>
          </div>
          <div className='row line-bottom'>
            <div className='col-12'>
              <h4 style={{ margin: '2.5rem 0 0.5rem' }}>Park hours:</h4>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <p className='less-padding'>{hours}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
