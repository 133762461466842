import { useContext, useEffect, useRef, useState } from 'react';
import API from 'Services/api';
import { SubHeader } from '..';
import DatePicker from 'react-datepicker';
import { TimeSlots, errorObj } from 'Lib/constants';
import { useRouter } from 'next/router';
import { Modal } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import Link from 'next/link';
import moment from 'moment';
import { TimerContext } from 'Lib/contexts/TimerContext';

const Index = ({ orderId }) => {
  const { clearTimer, getDeadTime, stopTimer } = useContext(TimerContext);
  const loseFocusRef = useRef(null);
  const [currReservationData, setCurrReservationData] = useState(null);
  const [formData, setFormData] = useState(null);
  const [pucDate, setPucDate] = useState('');
  const [qty, setQty] = useState('');
  const [timeSlots, setTimeSlots] = useState('');
  const [show, setShow] = useState(false);
  const [customerData, setCustomerData] = useState('');
  const [Quantity, setQuantity] = useState(null);
  const [showTimeSlots, setShowTimeSlots] = useState(TimeSlots);
  const [flag, setFlag] = useState(false);
  const [dataChanged, setDataChanged] = useState(true);
  
  const [dropOffTicketId, setDropOffTicketId] = useState('');

  const router = useRouter();

  useEffect(() => {
    const apiHit = async () => {
      try {
        const { data } = await API.get(`/reservations/${orderId}/details`);
        console.log(data?.data);
        const quant = data?.data?.reservationData?.quantity;
        let maxL = null;
        const TicketTypes = data?.data?.ticketData;
        for (let i = 0; i < TicketTypes.length; i++) {
          if (TicketTypes[i]?._id === data?.data?.reservationData?.ticketId) {
            maxL = TicketTypes[i]?.maxLimit;
            break;
          }
        }
        setCurrReservationData(data?.data?.pricingDetails?.pricingDetails);
        setFormData(data?.data?.reservationData);
        setPucDate(new Date(data?.data?.reservationData?.dateOfReservation));
        setQty(quant);
        setTimeSlots(data?.data?.reservationData?.timeslots);
        setCustomerData(data?.data?.customerData);
        maxL && setQuantity([...Array(maxL).keys()].map((i) => i + 1));
      } catch (err) {
        console.log(err);
      }
    };
    apiHit();
  }, []);

  useEffect(() => {
    async function getTimeSlots() {
      const { data } = await API.get(
        '/parks/' + formData.parkId + '/timeslots',
        {
          params: {
            segment: 'puc',
            ticketId: formData.ticketId,
            quantity: qty,
            date: moment(pucDate).format('YYYY-MM-DD'),
          },
        }
      );

      if (data?.message === 'Success') {
        const dropOffTypeFilter = data?.data?.ticketTypes.filter(ticketType => ticketType.option === 'Drop-off');
        if(dropOffTypeFilter.length) {
          setDropOffTicketId(dropOffTypeFilter[0].value);
        }
        
        // setShowTimeSlots(data?.data?.timeslots);
        // setFlag(false);
        const newTimeSlots = data?.data?.timeslots;
        setShowTimeSlots(newTimeSlots);
        setFlag(false);
        newTimeSlots.forEach((item) => {
          if (isSlotPresent(item.slotId) && item.capacity <= 0) {
            setTimeSlots((prev) =>
              prev.filter((slot) => slot.id != item.slotId)
            );
          }
        });
      }
    }
    if (qty !== '' && pucDate !== '' && pucDate) getTimeSlots();
    else {
      setFlag(true);
    }
  }, [pucDate, qty]);

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (formData.ticketId === dropOffTicketId) {
      localStorage.setItem(
        'updateReservation',
        JSON.stringify({
          reservationId: orderId,
          parkId: formData.parkId,
          date: moment(pucDate).format('YYYY-MM-DD'),
          ticketId: formData.ticketId,
          quantity: String(qty),
          timeslots: timeSlots,
        })
      );
      localStorage.setItem(
        'currReservation',
        JSON.stringify(currReservationData)
      );
      localStorage.setItem('orderNo', formData.orderNo);
      localStorage.setItem('billingInfo', JSON.stringify(customerData));
      localStorage.setItem('OrderId', orderId);
      stopTimer();
      clearTimer(getDeadTime());
      router.push('/update-reservation');
      return;
    }
    if (
      qty === '' ||
      timeSlots.length === 0 ||
      pucDate === null ||
      pucDate === ''
    ) {
      toast('All Fields are required', errorObj);
      handleClose();
      return;
    }

    if (timeSlots.length > 2) {
      toast('Maximum 2 consecutive Time Slots are allowed', errorObj);
      handleClose();
      return;
    }
    if (
      timeSlots.length === 2 &&
      Math.abs(timeSlots[0].id - timeSlots[1].id) > 1
    ) {
      toast('Maximum 2 consecutive Time Slots are allowed', errorObj);
      handleClose();

      return;
    }
    localStorage.setItem(
      'updateReservation',
      JSON.stringify({
        reservationId: orderId,
        parkId: formData.parkId,
        date: moment(pucDate).format('YYYY-MM-DD'),
        ticketId: formData.ticketId,
        quantity: String(qty),
        timeslots: timeSlots,
      })
    );
    localStorage.setItem(
      'currReservation',
      JSON.stringify(currReservationData)
    );
    localStorage.setItem('orderNo', formData.orderNo);
    localStorage.setItem('billingInfo', JSON.stringify(customerData));
    localStorage.setItem('OrderId', orderId);
    stopTimer();
    clearTimer(getDeadTime());
    router.push('/update-reservation');
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function isSlotPresent(slotId) {
    for (let i = 0; i < timeSlots.length; i++) {
      if (timeSlots[i].id == slotId) {
        return true;
      }
    }
    return false;
  }

  const getSlotClasses = (flag, capacity, selected) => {
    if (flag || capacity <= 0) return '';

    if (selected) return 'slot-sel';
    else return 'slot-des';
  };

  const handleAddTimeSlots = (item) => {
    setTimeSlots((prev) => {
      const selectTime = { id: item.slotId, label: item.label };
      let isTimePresent = false;

      const newTime = prev.filter((el) => {
        if (el.id == item.slotId) {
          isTimePresent = true;
          return false;
        }
        return true;
      });

      if (isTimePresent) {
        return newTime;
      }
      return [...prev, selectTime];
    });
  };

  const handleCheckReservationUpdate = async () => {
    // console.log('Hello');

    if (formData.ticketId !== dropOffTicketId) {
      if (
        qty === '' ||
        timeSlots.length === 0 ||
        pucDate === null ||
        pucDate === ''
      ) {
        toast('All Fields are required', errorObj);
        return;
      }

      if (timeSlots.length > 2) {
        toast('Maximum 2 consecutive Time Slots are allowed', errorObj);
        return;
      }
      if (
        timeSlots.length === 2 &&
        Math.abs(timeSlots[0].id - timeSlots[1].id) > 1
      ) {
        toast('Maximum 2 consecutive Time Slots are allowed', errorObj);

        return;
      }
    }

    try {
      const updatedData = {
        reservationId: orderId,
        parkId: formData.parkId,
        date: moment(pucDate).format('YYYY-MM-DD'),
        ticketId: formData.ticketId,
        timeslots: timeSlots,
        quantity: qty ? String(qty) : null,
      };
      const { data } = await API.post(
        '/reservations/checkReservationUpdate',
        updatedData
      );
      if (data.message === 'Success') {
        localStorage.setItem(
          'new_updated_details',
          JSON.stringify(data?.response)
        );
        localStorage.setItem(
          'currReservation',
          JSON.stringify(currReservationData)
        );
        localStorage.setItem('updateReservation', JSON.stringify(updatedData));

        localStorage.setItem('orderNo', formData.orderNo);
        localStorage.setItem('billingInfo', JSON.stringify(customerData));
        localStorage.setItem('OrderId', orderId);
        setDataChanged(data?.response?.dataChanged);
        handleShow();
        //document.getElementById('close-modal-button').click();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleGoBack = () => {
    router.push('/all-reservations');
    handleClose();
  };

  return (
    <div className='container'>
      <div>
        <Toaster />
      </div>
      <SubHeader currPage='allReservation' />
      <div className='row row-padding-top'>
        <div className='col-md-12'>
          <h1>Change reservation</h1>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-12'>
          <h3>Your current reservation:</h3>
        </div>
      </div>
      <div className='row line-bottom'>
        <div className='col-xl-2 col-md-2 col-3'>
          <p className='less-padding'>
            <strong>Ticket</strong>
          </p>
        </div>
        <div className='col-xl-2 col-md-2 col-2'>
          <p className='less-padding text-center'>
            <strong>Qty</strong>
          </p>
        </div>
        <div className='col-xl-2 col-md-2 col-4'>
          <p className='less-padding'>
            <strong>Date</strong>
          </p>
        </div>
        <div className='col-xl-3 col-md-4 col-3'>
          <p className='less-padding'>
            <strong>Time</strong>
          </p>
        </div>
        <div className='col-xl-3 col-md-2 col-0 text-end'></div>
      </div>

      {currReservationData &&
        currReservationData.map((reservation) => {
          return (
            <div className='row line-bottom'>
              <div className='col-xl-2 col-md-2 col-3'>
                <p className='less-padding'>{reservation.ticket}</p>
              </div>
              <div className='col-xl-2 col-md-2 col-2'>
                <p className='less-padding text-center'>{reservation.qty}</p>
              </div>
              <div className='col-xl-2 col-md-2 col-4'>
                <p className='less-padding'>{reservation.date}</p>
              </div>
              <div className='col-xl-6 col-md-6 col-3'>
                <p className='less-padding'>{reservation.time}</p>
              </div>
            </div>
          );
        })}

      {formData && (
        <form>
          <div style={{ border: '1px solid #bdd4db' }}>
            <div className='row reservation'>
              <div className='col-12'>
                <h3>Change your reservation to:</h3>
                <div className='row row-right-left-padding'>
                  <div className='col-md-4 col-sm-4 col-xs-12'>
                    <select
                      className='form-control'
                      id='exampleFormControlSelect2'
                      aria-label='Ticket type'
                      disabled
                    >
                      <option selected>{formData.ticketName}</option>
                    </select>
                  </div>

                  <div className='col-md-3 col-sm-3 col-xs-12'>
                    <div className='input-group input-daterange'>
                      <DatePicker
                        id='datefield'
                        minDate={new Date(Date.now())}
                        maxDate={
                          new Date(
                            Date.now() +
                              3600 *
                                1000 *
                                24 *
                                parseInt(
                                  process.env.NEXT_PUBLIC_RESERVATION_WINDOW
                                    ? process.env.NEXT_PUBLIC_RESERVATION_WINDOW
                                    : '3'
                                )
                          )
                        }
                        selected={pucDate}
                        onChange={(date) => setPucDate(date)}
                        placeholderText='Booking Date'
                      />
                    </div>
                  </div>

                  {formData.ticketId !== dropOffTicketId && (
                    <div className='col-md-2 col-sm-2 col-xs-12'>
                      {Quantity ? (
                        <select
                          className='form-control'
                          id='Quantity'
                          aria-label='Quantity'
                          onChange={(e) => setQty(e.target.value)}
                        >
                          {Quantity.map((item) => (
                            <option key={item} selected={item === qty}>
                              {item}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          type='number'
                          className='form-control'
                          id='Quantity'
                          aria-label='Quantity'
                          onChange={(e) => setQty(e.target.value)}
                          value={qty}
                          placeholder='Entry'
                        />
                      )}
                    </div>
                  )}
                </div>

                {formData.ticketId !== dropOffTicketId && (
                  <div className='row row-right-left-padding'>
                    <div className='col-md-12 col-sm-12 col-xs-12'>
                      <div className='input-group mb-3'>
                        {showTimeSlots.map((item) => (
                          <a
                            key={item.slotId}
                            className={`btn btn-primary ${getSlotClasses(
                              flag,
                              item.capacity,
                              isSlotPresent(item.slotId)
                            )} time ${
                              (flag || item.capacity <= 0) && 'booked'
                            }`}
                            style={{
                              backgroundColor:
                                isSlotPresent(item.slotId) && '#396a5b',
                              color: isSlotPresent(item.slotId) && '#fff',
                              pointerEvents:
                                (flag || item.capacity <= 0) && 'none',
                            }}
                            onClick={() => handleAddTimeSlots(item)}
                          >
                            {item.label}
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='row row-padding-top'>
            <div className='col-md-8'>
              <p>
                <em>
                  * If reservation date is less than 3 days then there will be
                  no refund.
                </em>
              </p>
            </div>
            <div className='col-md-4'>
              <Link href='/search-reservations'>
                <a className='btn btn-primary btn-outline-primary'>Cancel</a>
              </Link>

              <button
                type='button'
                className='btn btn-primary ml-2'
                onClick={handleCheckReservationUpdate}
              >
                Save all changes
              </button>

              <Modal show={show} onHide={handleClose}>
                <Modal.Body>
                  <div className='modal-header text-start'>
                    <div className='row'>
                      <div className='col-md-12'>
                        <p>Are you sure you want to update the reservation?</p>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    className='btn btn-primary btn-outline-primary'
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                  {dataChanged ? (
                    <button
                      type='submit'
                      className='btn btn-primary'
                      onClick={() => {
                        stopTimer();
                        clearTimer(getDeadTime());
                        router.push('/update-reservation');
                        handleClose();
                      }}
                    >
                      Confirm
                    </button>
                  ) : (
                    <button onClick={handleGoBack} className='btn btn-primary'>
                      Go Back
                    </button>
                  )}
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </form>
      )}

      <br />
      <br />
      <br />
    </div>
  );
};

export default Index;
