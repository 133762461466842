import { TimerContext } from 'Lib/contexts/TimerContext';
import React, { useContext } from 'react';

const Timer = () => {
  const { timer } = useContext(TimerContext);

  return (
    <div className='col-md-4 col-sm-4 col-xs-12'>
      <p className='text-end'>
        <b>Cart expires in:</b>
        <br />
        {timer}
      </p>
    </div>
  );
};

export default Timer;
