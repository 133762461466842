import { useRouter } from 'next/router';
import moment from 'moment';
import React from 'react';
import API from 'Services/api';

export default function ReservationEntries({
  index,
  dataItem,
  edit = true,
  removeCartItem,
}) {
  const router = useRouter();
  const {
    entryPrice,
    reservationDetailData,
    ticketPricingData,
    pricingData,
    total,
  } = dataItem;
  const {
    dateOfReservation,
    ticketName,
    quantity,
    parkId,
    ticketId,
    totalPrice,
    timeslots,
    _id,
  } = reservationDetailData;
  const { pricingDetails } = pricingData;
  const { price } = ticketPricingData;
  // var label = timeslots[0]?.label;
  const handleOnClickRemove = () => {
    // console.log(index)
    removeCartItem(_id, index, totalPrice);

    $(`#exampleModal${index}`).modal('hide');
  };

  const handleChangeBtn = () => {
    const updateReservationData = {
      dateOfReservation,
      _id,
      timeslots,
      quantity,
      parkId,
      ticketId: { _id: ticketId },
    };

    localStorage.setItem(
      'updateReservationData',
      JSON.stringify(updateReservationData)
    );
    router.push('/reservations');
  };

  return (
    <div>
      <div>
        <div className='row'>
          <div className='col-md-8 col-sm-8 col-xs-12'>
            <h3>Reservation #{index + 1}</h3>
          </div>
          {edit && (
            <div
              className='col-md-4 col-sm-4 col-xs-12 text-end'
              style={{ paddingTop: '15px' }}
            >
              <button
                className='btn btn-primary btn-outline-primary'
                onClick={handleChangeBtn}
              >
                Change
              </button>

              <button
                type='button'
                className='btn btn-primary btn-outline-primary ml-2'
                data-toggle='modal'
                data-target={`#exampleModal${index}`}
              >
                Remove
              </button>

              <div
                className='modal fade'
                id={`exampleModal${index}`}
                tabIndex='-1'
                role='dialog'
                aria-labelledby='exampleModalLabel'
                aria-hidden='true'
              >
                <div
                  className='modal-dialog modal-dialog-centered modal-dialog-scrollable'
                  role='document'
                >
                  <div className='modal-content'>
                    <div className='modal-header text-start'>
                      <p>
                        <b>Are you sure?</b>
                        <br />
                        Removing this reservation will immediately release the
                        reservation tickets and they may no longer be available.
                      </p>
                    </div>
                    <div className='modal-footer'>
                      {/* <a
                        className='btn btn-primary'
                        href='puc-cart-reservation-removed.html'
                      >
                        Remove
                      </a>{' '} */}

                      {/* Remove Button  */}
                      <button
                        className='btn btn-primary'
                        onClick={handleOnClickRemove}
                      >
                        Remove
                      </button>
                      <button
                        type='button'
                        data-dismiss='modal'
                        className='btn btn-primary btn-outline-primary'
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className='row line-bottom'>
          <div className='col-xl-4 col-md-4 col-3'>
            <p className='less-padding'>
              <strong>Ticket</strong>
            </p>
          </div>
          <div className='col-xl-1 col-md-2 col-2'>
            <p className='less-padding'>
              <strong>Qty</strong>
            </p>
          </div>
          <div className='col-xl-2 col-md-2 col-3'>
            <p className='less-padding'>
              <strong>Date</strong>
            </p>
          </div>
          <div className='col-xl-2 col-md-2 col-2'>
            <p className='less-padding'>
              <strong>Time</strong>
            </p>
          </div>
          <div className='col-2 text-end'>
            <p className='less-padding'>
              <strong>Price</strong>
            </p>
          </div>
        </div>
        {/* {timeslots.map((item) => (
          <div className='row line-bottom'>
            <div className='col-xl-4 col-md-4 col-3'>
              <p className='less-padding'>{ticketName}</p>
            </div>
            <div className='col-xl-1 col-md-2 col-2'>
              <p className='less-padding'>1</p>
            </div>
            <div className='col-xl-2 col-md-2 col-3'>
              <p className='less-padding'>{dateOfReservation}</p>
            </div>
            <div className='col-xl-2 col-md-2 col-2'>
              <p className='less-padding'>{item.label}</p>
            </div>
            <div className='col-2 text-end'>
              <p className='less-padding'>${price}</p>
            </div>
          </div>
        ))}

        <div className='row line-bottom'>
          <div className='col-xl-4 col-md-4 col-3'>
            <p className='less-padding'>Entry</p>
          </div>
          <div className='col-xl-1 col-md-2 col-2'>
            <p className='less-padding'>{quantity}</p>
          </div>
          <div className='col-xl-2 col-md-2 col-3'>
            <p className='less-padding'>
              {moment(dateOfReservation).format('MM/DD/YYYY')}
            </p>
          </div>
          <div className='col-xl-2 col-md-2 col-2'>
            <p className='less-padding'></p>
          </div>
          <div className='col-2 text-end'>
            <p className='less-padding'>${entryPrice}</p>
          </div>
        </div> */}

        {pricingDetails.map((ticket, idx) => {
          return (
            <div className='row line-bottom' key={idx}>
              <div className='col-xl-4 col-md-4 col-3'>
                <p className='less-padding'>{ticket.ticket}</p>
              </div>
              <div className='col-xl-1 col-md-2 col-2'>
                <p className='less-padding'>{ticket.qty}</p>
              </div>
              <div className='col-xl-2 col-md-2 col-3'>
                <p className='less-padding'>{ticket.date}</p>
              </div>
              <div className='col-xl-2 col-md-2 col-2'>
                <p className='less-padding'>{ticket.time}</p>
              </div>
              <div className='col-2 text-end'>
                <p className='less-padding'>${ticket.price}</p>
              </div>
            </div>
          );
        })}

        <div className='row line-bottom-dark'>
          <div className='col-xl-4 col-md-4 col-3'></div>
          <div className='col-xl-2 col-md-3 col-3'></div>
          <div className='col-xl-3 col-md-4 col-4'>
            <p className='less-padding text-end'>Total</p>
          </div>
          <div className='col-2 text-end'>
            <p className='less-padding'>${totalPrice}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
