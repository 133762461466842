import React, { useEffect, useState } from 'react';
import API from 'Services/api';
import { SubHeader } from '..';
import { Formik, Form } from 'formik';
import { MyTextInput } from 'Atoms/Formik';
import { PUCEmailCopySchema } from 'Lib/validators';
import toast, { Toaster } from 'react-hot-toast';
import { Loader } from 'Atoms/';

function index() {
  const [ticketData, setTicketData] = useState(null);
  const [oldTicketData, setOldTicketData] = useState(null);
  const [oldAmount, setOldAmount] = useState(null);
  const [orderId, setOrderId] = useState('');
  const [newData, setNewData] = useState(null);

  useEffect(() => {
    const paymentData = localStorage.getItem('paymentData');
    const oldData = localStorage.getItem('currReservation');
    const newDataDetails = JSON.parse(
      localStorage.getItem('new_updated_details')
    );

    if (paymentData) {
      const parsedPay = JSON.parse(paymentData);
      const parsedOldData = JSON.parse(oldData);
      setNewData(newDataDetails);

      setTicketData(parsedPay);
      setOldTicketData(parsedOldData);
      setOldAmount(localStorage.getItem('oldAmount'));
      setOrderId(localStorage.getItem('OrderId'));
    }
  }, []);

  const handleDownload = async () => {
    const { data } = await API.get('/puc/downloadTicket', {
      params: { reservationId: orderId },
      responseType: 'blob',
    });

    const url = URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    // link.download = ticketData.reservationData.orderNo + '.pdf';
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (!ticketData) return <Loader />;
  return (
    <>
      <div className='container'>
        <div>
          <Toaster />
        </div>
        <SubHeader currPage='allReservation' />
        <div className='row row-padding'>
          <div className='col-md-12'>
            <h1>Your order has been successfully processed!</h1>
            <p>
              A copy of this confirmation has been sent to {''}
              {ticketData.customerData.email}.
              <br />
              <em>
                There is limited cellular connectivity at the Park. You MUST
                download or print the ticket prior to arrival.
              </em>
            </p>
          </div>
        </div>

        <div className='row'>
          <div className='col-xl-2 col-4'>
            <img
              src={
                ticketData.reservationData.QRLink
                  ? ticketData.reservationData.QRLink
                  : '../images/qr-code.png'
              }
              className='img-fluid'
            />
          </div>
          <div className='col-md-7 col-8'>
            <h3 style={{ margin: '0.5rem 0px 1.5rem 0px' }}>
              Order #{ticketData.reservationData.orderNo}
            </h3>
            <p style={{ paddingTop: '.2rem' }}>
              <strong>Your one time QR code for entry is to the left</strong>
            </p>
          </div>
          <div
            className='col-md-3 col-12 text-end'
            style={{ paddingBottom: '1.5rem' }}
          >
            <button className='btn btn-primary' onClick={handleDownload}>
              Download ticket
            </button>
          </div>
        </div>
        <div className='row line-bottom highlight mt-2'>
          <div className='col-xl-3 col-md-3 col-3'>
            <p className='less-padding'>Original Reservation Details</p>
          </div>
        </div>

        <div className='row line-bottom'>
          <div className='col-xl-3 col-md-3 col-3'>
            <p className='less-padding'>
              <strong>Ticket</strong>
            </p>
          </div>
          <div className='col-xl-1 col-md-2 col-1'>
            <p className='less-padding'>
              <strong>Qty</strong>
            </p>
          </div>
          <div className='col-xl-2 col-md-2 col-3'>
            <p className='less-padding'>
              <strong>Date</strong>
            </p>
          </div>
          <div className='col-xl-2 col-md-3 col-3'>
            <p className='less-padding'>
              <strong>Time</strong>
            </p>
          </div>
          <div className='col-1 text-end'>
            <p className='less-padding'>
              <strong>Price</strong>
            </p>
          </div>
        </div>

        {oldTicketData.map((price, i) => {
          return (
            <div key={i} className='row line-bottom'>
              <div className='col-xl-3 col-md-3 col-3'>
                <p className='less-padding'>{price.ticket}</p>
              </div>
              <div className='col-xl-1 col-md-2 col-1'>
                <p className='less-padding'>{price.qty}</p>
              </div>
              <div className='col-xl-2 col-md-2 col-3'>
                <p className='less-padding'>{price.date}</p>
              </div>
              <div className='col-xl-2 col-md-3 col-3'>
                <p className='less-padding'>{price.time}</p>
              </div>
              <div className='col-1 text-end'>
                <p className='less-padding'>${price.price}</p>
              </div>
            </div>
          );
        })}

        <div className='row line-bottom-dark'>
          <div className='col-xl-3 col-md-3 col-3'></div>
          <div className='col-md-1 col-2'></div>
          <div className='col-xl-2 col-md-3 col-2'></div>
          <div className='col-xl-2 col-md-2 col-4'>
            <p className='less-padding text-end'>Original Charge</p>
          </div>
          <div className='col-1 text-end'>
            <p className='less-padding'>${oldAmount}</p>
          </div>
        </div>

        <div className='row line-bottom highlight'>
          <div className='col-xl-3 col-md-3 col-3'>
            <p className='less-padding'>Updated Reservation Details</p>
          </div>
        </div>

        {/* {ticketData.pricingDetails.pricingDetails.map((price, i) => (
          <div key={i} className='row line-bottom'>
            <div className='col-xl-3 col-md-3 col-3'>
              <p className='less-padding'>{price.ticket}</p>
            </div>
            <div className='col-xl-1 col-md-2 col-1'>
              <p className='less-padding'>{price.qty}</p>
            </div>
            <div className='col-xl-2 col-md-2 col-3'>
              <p className='less-padding'>{price.date}</p>
            </div>
            <div className='col-xl-2 col-md-3 col-3'>
              <p className='less-padding'>{price.time}</p>
            </div>
            <div className='col-1 text-end'>
              <p className='less-padding'>${price.price}</p>
            </div>
          </div>
        ))}
        <div className='row line-bottom-dark'>
          <div className='col-xl-3 col-md-3 col-3'></div>
          <div className='col-md-1 col-1'></div>
          <div className='col-xl-1 col-md-2 col-2'></div>
          <div className='col-xl-3 col-md-3 col-4'>
            <p className='less-padding text-end'>New Charge Total</p>
          </div>
          <div className='col-1 text-end'>
            <p className='less-padding'>${ticketData.pricingDetails.total}</p>
          </div>
        </div> */}

        {newData.updatedReservationDetails.map((item) => {
          if (item.ticket === 'New charge') {
            return (
              <div className='row line-bottom'>
                <div className='col-xl-3 col-md-3 col-3'></div>
                <div className='col-md-1 col-1'></div>
                <div className='col-xl-2 col-md-3 col-2'></div>
                <div className='col-xl-2 col-md-2 col-4'>
                  <p className='less-padding text-end'>{item.ticket}</p>
                </div>
                <div className='col-1 text-end'>
                  <p className='less-padding'>${item.price}</p>
                </div>
              </div>
            );
          }
          return (
            <div className='row line-bottom'>
              <div className='col-xl-3 col-md-3 col-3'>
                <p className='less-padding'>
                  {item.ticket === 'Amount due'
                    ? 'Additional Charge'
                    : item.ticket}
                </p>
              </div>
              <div className='col-xl-1 col-md-2 col-1'>
                <p className='less-padding'>{item.qty}</p>
              </div>
              <div className='col-xl-2 col-md-2 col-3'>
                <p className='less-padding'>{item.date}</p>
              </div>
              <div className='col-xl-2 col-md-3 col-3'>
                <p className='less-padding'>{item.time}</p>
              </div>
              <div className='col-1 text-end'>
                <p className='less-padding'>
                  {item.price !== 'Waived' ? `$${item.price}` : item.price}
                </p>
              </div>
            </div>
          );
        })}

        {ticketData.customerData && (
          <div className='row line-bottom'>
            <div className='col-xl-8 col-10'>
              <div className='row'>
                <div className='col-xl-6 col-12'>
                  <p className='less-padding' style={{ paddingTop: '.2rem' }}>
                    <strong>Customer details:</strong>
                  </p>
                </div>
              </div>

              <div className='row'>
                <div className='col-xl-3 col-5'>
                  <p className='less-padding'>Company:</p>
                </div>
                <div className='col-xl-5 col-7'>
                  <p className='less-padding'>
                    {ticketData.customerData.company}
                  </p>
                </div>
              </div>

              <div className='row'>
                <div className='col-xl-3 col-5'>
                  <p className='less-padding'>Name:</p>
                </div>
                <div className='col-xl-5 col-7'>
                  <p className='less-padding'>
                    {ticketData.customerData.firstName +
                      ' ' +
                      ticketData.customerData.lastName}
                  </p>
                </div>
              </div>

              <div className='row'>
                <div className='col-xl-3 col-5'>
                  <p className='less-padding'>Email:</p>
                </div>
                <div className='col-xl-5 col-7'>
                  <p className='less-padding'>
                    {ticketData.customerData.email}
                  </p>
                </div>
              </div>

              <div className='row'>
                <div className='col-xl-3 col-5'>
                  <p className='less-padding'>Telephone:</p>
                </div>
                <div className='col-xl-5 col-7'>
                  <p className='less-padding'>
                    {ticketData.customerData.mobile}
                  </p>
                </div>
              </div>

              <div className='row'>
                <div className='col-xl-3 col-5'>
                  <p className='less-padding'>PUC License #:</p>
                </div>
                <div className='col-xl-5 col-7'>
                  <p className='less-padding'>
                    {ticketData.customerData.pucLicense}
                  </p>
                </div>
              </div>

              <div className='row'>
                <div className='col-xl-3 col-5'>
                  <p className='less-padding'>Address:</p>
                </div>
                <div className='col-xl-5 col-7'>
                  <p className='less-padding'>
                    {ticketData.customerData.address}
                    <br />
                    {`${ticketData.customerData.city} ${ticketData.customerData.stateProvince} ${ticketData.customerData.zip}`}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className='row'>
          <div className='col-xl-12 col-md-12'>
            <h3>Email copy of this ticket</h3>
          </div>
        </div>

        <Formik
          initialValues={{ email: '', verifyEmail: '' }}
          validationSchema={PUCEmailCopySchema}
          onSubmit={async ({ email }, { resetForm }) => {
            // console.log(email);
            try {
              const { data } = await API.post(`reservations/${orderId}/email`, {
                email,
              });
              if (data?.message === 'Success') {
                // console.log(data);
                toast.success(`A copy has been delivered to ${email}`);
                resetForm();
              }
            } catch (error) {
              console.log(error);
            }
          }}
        >
          {(props) => {
            return (
              <Form className='row k' style={{ paddingBottom: '3.5rem' }}>
                <div className='col-xl-5 col-md-5'>
                  <label htmlFor='email'>Email*</label>
                  <MyTextInput
                    name='email'
                    type='email'
                    className='form-control'
                  />
                </div>

                <div className='col-xl-5 col-md-5'>
                  <label htmlFor='verifyEmail'>Verify Email*</label>
                  <MyTextInput
                    name='verifyEmail'
                    type='email'
                    className='form-control'
                  />
                </div>

                <div
                  className='col-xl-2 col-md-2'
                  style={{ paddingTop: '35px' }}
                >
                  <button type='submit' className='btn btn-primary'>
                    Send
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
}

export default index;
