import React from 'react';
import { ErrorMessage, Field } from 'formik';
import TextError from '../TextError';

const Index = (props) => {
  const { name, options, ...rest } = props;
  return (
    <div>
      <Field name={name}>
        {({ field }) => {
          return options.map((option) => {
            return (
              <div className='form-check form-check-inline' key={option.key}>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id={option.value}
                  {...field}
                  {...rest}
                  value={option.value}
                  checked={field.value.includes(option.value)}
                />
                <label className='form-check-label' htmlFor={option.value}>
                  {option.key}
                </label>
              </div>
            );
          });
        }}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
};

export default Index;
